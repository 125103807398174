import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Main/Components/Footer/Footer';
import Header from '../Main/Components/Header/Header';
import Test from './Components/Test/Test';
import TestsHeader from './Components/TestsHeader/TestsHeader';
import { TestsArray } from '../../Arrays/TestsArray'

const Tests = () => {


const ReturnTests = () => {
return TestsArray.map(el => <Link to={`/test/${el.testId}`}><Test header = {el.testName} description = {el.testDescription} price = {el.price} level = {el.level} time = {el.time}/></Link> )
}
    return (
        <div className='tests'>
            <Header></Header>
            <TestsHeader></TestsHeader>
            <div className='tests__container'>
            {ReturnTests()}
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Tests;