import {makeAutoObservable} from "mobx";   
import {AuthService} from "../services/AuthService";



export default class AuthStore {
    user = {id: '', login:''};
    userInfo = {}
    isAuth = false;
    isLoading = true;
    testsDone = [];
    balance = null;
    error = '';

    constructor() {
        makeAutoObservable(this);
    }
    

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;

    }

    setBalance(number) {
        this.balance = number
    }
   
    setLoading(bool) {
        this.isLoading = bool
    }
    setTests(arr) {
        this.testsDone = arr
    }
    
    setInfo(info) {

        this.userInfo = info;
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    
    setError(error) {
        this.error = error;
    }
    async logout() {
        const response = await AuthService.logout()
        console.log(response)
    }

    async login(login, password) {

        try {
            
            const response = await AuthService.login(login, password);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('user', this.user.id);
            console.log(response)
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setTests(response.data.info.testsDone)
            this.setError('')
        } catch (e) {   
            this.setError(e.response?.data)
            console.log(this.error)
            console.log(e)
        }
    }

    async registration(obj) {
        const {login, password, firstName, lastName, patronymic, email} = obj;
        try {
            const response = await AuthService.registration(login, password, firstName, lastName, patronymic, email);
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setInfo(response.data.userInfo);
            this.setBalance(response.data.info.balance)  
            localStorage.setItem('user', this.user.id);
            this.setError('')
        } catch (e) {
            this.setError(e.response?.data)
          console.log(e)
    }
    }
    async checkAuth() {
        try {
            
            const response = await AuthService.checkUser()
            console.log(response)
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setInfo(response.data.userInfo);
            this.setTests(response.data.info.testsDone)  
            this.setBalance(response.data.info.balance)  
            localStorage.setItem('user', this.user.id);      
            this.setLoading(false)
        } catch (e) {
            console.log(e)
            console.log(e.response?.data?.message);
        } 
    }
}