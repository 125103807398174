import React, {useContext, useEffect} from 'react';
import Footer from '../Main/Components/Footer/Footer';
import Header from '../Main/Components/Header/Header';
import {Link, useNavigate } from 'react-router-dom';
import { authContext, balanceContext } from '../..';
import { observer } from 'mobx-react';
import CreatePDF from '../CreatePDF/CreatePDF';
import usePDF from '@react-pdf/renderer';
import TestPDFHook from '../CreatePDF/TestPDFHook';

const Cabinet = () => {


const {balanceStore} = useContext(balanceContext)
const {authStore} = useContext(authContext)




const navigate = useNavigate()


const {firstName, lastName, patronymic, email} = authStore.userInfo
       

const ChangeBalance = () => {
    const newBalance = prompt('сумма', 0)
    balanceStore.setBalance(newBalance)
    const id = localStorage.getItem('user')
    balanceStore.updateBalance(id, newBalance)
}

console.log(localStorage.getItem('token'))

useEffect( () => {if(localStorage.getItem('token') === null) {navigate('/')}},[]);

    return (
        <div className='cabinet'>
            <Header></Header>
            <div className='cabinet__container'>
            <div className='cabinet__personal-data'>
            <span className='cabinet__personal-data_item'>ПІБ: {lastName} {firstName} {patronymic}</span>
            <span className='cabinet__personal-data_item'>Пошта: {email}</span>
            {/* <button  style ={{width: '150px'}} className='button'>редагувати дані</button> */}
            </div>
            <div className='cabinet__balance'>
            <span className='cabinet__balance_header'>Ваш Баланс: {balanceStore.balance}</span>                        </div>
            <div className='cabinet__sertificates'>
                <h1> Ваші сертифікати</h1>
                <ul className='cabinet__sertificates_container'>
                {authStore.testsDone.map( el => 
                    <li className='cabinet__sertificate'>
                    <div className='cabinet__sertificate_name'>Назва: {el.testName}</div>
                    <div className='cabinet__sertificate_name'>Власник: {el.bearer}</div>
                    
                    <CreatePDF bearer = {el.bearer} testName = {el.testName}/>

{/*             
                    <Link to = '/createPDF'><div>друкувати сертифікат</div></Link> */}
                    </li>
                )}
                </ul>
                </div>
            </div>
                <Footer></Footer>
        </div>
    );
};

export default observer(Cabinet);