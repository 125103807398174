import React from "react";
import Header from "../../../Main/Components/Header/Header";
import Footer from "../../../Main/Components/Footer/Footer";

const LegalInfo = () => {
  return (
    <div className="legal-info">
      <Header />
      <div className="legal-info__header_container">
        <h2 className="legal-info__header">Юридична інформація і контакти</h2>
        <div className="legal-info__header_description">
          На цій сторінці ви знайдете детальную інформацію стосовно юридичного
          аспекту діяльності школи{" "}
        </div>
      </div>
      <div className="legal-info__container">
        <div className="legal-info__types">
          <div className="legal-info__types_header">
            Види економічної діяльності (КВЕД)
          </div>
          <div className="legal-info__types_type">
            85.32 Професійно-технічна освіта
          </div>
          <div className="legal-info__types_type">
            85.59 Інші види освіти, н. в. і. у
          </div>
          <div className="legal-info__types_type">
            70.22 Консультування з питань комерційної діяльності й керування
          </div>
          <div className="legal-info__types_type">
            85.60 Допоміжна діяльність у сфері освіти
          </div>
        </div>

        <div className="legal-info__adress">
          <h3 className="legal-info__adress_header">Юридична адреса</h3>
          <p className="legal-info__adress_text">
            Україна, місто Київ, Дніпровський район, вул. Регенераторна 4, 02160
          </p>
        </div>

        <div className="legal-info__contacts">
          <h3 className="legal-info__contacts_header">Контакти</h3>
          <div className="legal-info__contacts_container">
            <a
              href="tel:+380980625668"
              className="legal-info__contacts_contact phone"
            >
              +38 (098) 062-58-68
            </a>
            <div className="legal-info__contacts_contact mail">
              info@w-ts.com.ua
            </div>
            <a
              href="https://www.facebook.com/people/%D0%A1%D0%B5%D0%BC%D1%96%D0%BD%D0%B0%D1%80-%D1%82%D1%80%D0%B5%D0%BD%D1%96%D0%BD%D0%B3-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-WTS/100064810217621/"
              className="legal-info__contacts_contact facebook"
            >
              Семінар-тренінг школа WTS
            </a>
            <a
              href="https://www.instagram.com/wts_school/?igshid=MzRlODBiNWFlZA%3D%3D"
              className="legal-info__contacts_contact instagram"
            >
              wts_school
            </a>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default LegalInfo;
