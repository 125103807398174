import React from 'react';
import Header from '../Main/Components/Header/Header';
import Footer from '../Main/Components/Footer/Footer';
import equipmentPDF from '../Programs/PDF/Equipment.pdf';
import nationalCuisinePDF from '../Programs/PDF/national_cuisine.pdf'
import procOrganizationPDF from '../Programs/PDF/Proc_organization.pdf'
import prodTechnologyPDF from '../Programs/PDF/Prod_technology.pdf'
import restaurantsBasicsPDF from '../Programs/PDF/restaurants_basics.pdf'
import Program from './Program';
// import { ProgramsArray } from '../../Arrays/ProgramsArray';

import styled from 'styled-components'

const StyledHeader = styled.h1`

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    height: 200px;
    font-size: 2rem;
    background: var(--linear-gradient)
    
    
    

`


const Programs = () => {
    return (
        <div className='programs__container'>
            <Header></Header>
            <StyledHeader> Список програм підвищення кваліфікації </StyledHeader>
            <div className='programs__list'>
           <Program program = {equipmentPDF} header = {'Особливості експлуатації і технічного сервісу технологічного обладнання харчових виробництв'}/>
           <Program program = {nationalCuisinePDF} header = {'Особливості кухонь народів світу'}/>
           <Program program = {procOrganizationPDF} header = {'Організація виробництва, сервісу та обслуговування в закладах ресторанного господарства'}/>
           <Program program = {prodTechnologyPDF} header = {'Технологія виробництва кулінарної продукції'}/>
           <Program program = {restaurantsBasicsPDF} header = {'Основи ресторанної справи'}/>
                
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Programs;