
  
interface Links {
    linkId?: number | null;
    link: string;
    linkTitle: string
    linkDescription: string;
  }


  export const LinksArray: Array<Links> = [
    {
      link: "https://zakon.rada.gov.ua/laws/show/z1704-12#Text",
      linkTitle:
        "Закон України про затвердження Вимог щодо розробки, впровадження та застосування постійно діючих процедур, заснованих на принципах Системи управління безпечністю харчових продуктів (НАССР)",
      linkDescription: "Посилання на закон № 1704/22016 від 9 жовтня 2012 року",
    },
    {
      linkTitle:
        "Закон України про затвердження Змін до Вимог щодо розробки, впровадження та застосування постійно діючих процедур, заснованих на принципах Системи управління безпечністю харчових продуктів (НАССР)",
      link: "https://zakon.rada.gov.ua/laws/show/z1517-15#n2",
      linkDescription: "Посилання на закон № 1517/27962 від 07 грудня 2015 р.",
    },
    {
      linkTitle:
        "Наказ про внесення змін до наказу Міністерства охорони здоров’я України від 23 липня 2002 року № 280",
      link: "https://zakon.rada.gov.ua/laws/show/z0662-13#n4",
      linkDescription: "Посилання на наказ № 662/23194 від 23 квітня 2013 р.",
    },
    {
      linkTitle:
    'Закон України про основні принципи та вимоги до безпечності та якості харчових продуктів',
      link: "https://zakon.rada.gov.ua/laws/show/771/97-%D0%B2%D1%80#Text",
      linkDescription: "Посилання на закон № 771/97-ВР. Остання редакція від 31 березня 2023 р.",
    },

    {
    linkTitle: 'Наказ про внесення змін до наказу Міністерства охорони здоров`я України від 23 липня 2002 року № 280',
    link: 'https://zakon.rada.gov.ua/laws/show/z0662-13#n4;=l=',
    linkDescription: 'Посилання на наказ № 662/23194 від 23 квітня 2013 р'
},
    {
        linkTitle: 'Наказ про затвердження Державних санітарних норм та правил "Гігієнічні вимоги до води питної, призначеної для споживання людиною" (ДСанПіН 2.2.4-171-10)',
        link: 'https://zakon.rada.gov.ua/laws/show/z0452-10#Text',
        linkDescription: 'Посилання на наказ № 452/17747. Остання редакція від 22 березня 2022 р'
    },
    {
        linkTitle: 'Закон про питну воду та питне водопостачання',
        link: 'https://zakon.rada.gov.ua/laws/show/2918-14#Text',
        linkDescription: 'Посиланная на закон 2918-III. Редакція від 07 серпня 2023 р.'
    }


  ];