import {api} from '../http/index'

class TestsService {
    
    static async createSertificate(id, data) {
        return await api.post('/createSertificate', {id, data})
    }

}

export {TestsService}