import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    // <div className="footer">
    //   <div className="footer__header">
    //     <div className="footer__links_container">
    //       <Link
    //         to="https://www.facebook.com/people/%D0%A1%D0%B5%D0%BC%D1%96%D0%BD%D0%B0%D1%80-%D1%82%D1%80%D0%B5%D0%BD%D1%96%D0%BD%D0%B3-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0-WTS/100064810217621/"
    //         className="footer__links_link"
    //       />
    //       <Link
    //         to="https://instagram.com/wts_school?igshid=MzRlODBiNWFlZA=="
    //         className="footer__links_link"
    //       ></Link>
    //     </div>
    //     <div className="footer__header_adress">М.Київ, Регенераторна 4</div>
    //     <div className="footer__images">
    //       <div className="footer__image"></div>
    //       <div className="footer__image"></div>
    //     </div>
    //     <div className="footer__header_phone">+38 (098) 062-58-68</div>
    //     <div className="footer__header_email">info@w-ts.com.ua</div>
    //   </div>
    // </div>

    <div className="footer-second__container">
      <div className="footer-second__logo_container">
        <div className="footer-second__logo_image">WTS</div>
        <div className="footer-second__logo_description">
          Впроваджуємо HACCP легко і без зусиль
        </div>
        <div className="footer-second__logo_png"></div>
        <div className="footer-second__logo_links-container">
          <div className="footer-second__logo_link facebook"></div>
          <div className="footer-second__logo_link instagram"></div>
        </div>
      </div>

      <div className="footer-second__navigation_container">
        <ul className="footer-second__navigation_group">
          <li className="footer-second__navigation_header">Навігація</li>
          <Link to="/courses" className="footer-second__navigation_link">
            Актуальні курси
          </Link>
          <Link to="/articles" className="footer-second__navigation_link">
            Корисні матеріали
          </Link>
          <Link to="/sertificates" className="footer-second__navigation_link">
            Перевірка сертифікатів
          </Link>
          <Link to="/cabinet" className="footer-second__navigation_link">
            Особистий кабінет
          </Link>
        </ul>
        <ul className="footer-second__navigation_group">
          <li className="footer-second__navigation_header">Швидкі посилання</li>
          <Link
            to="/about/legal-info"
            className="footer-second__navigation_link"
          >
            Контакти
          </Link>
          <Link to="/about/offer" className="footer-second__navigation_link">
            Публічна оферта
          </Link>
          <Link to="/about/payment" className="footer-second__navigation_link">
            Реквізити для оплати
          </Link>
        </ul>
        <ul className="footer-second__navigation_group">
          <li className="footer-second__navigation_header">Маєте питання?</li>
          <li className="footer-second__navigation_link">
            <a href="tel:+380980625668">+38 (098) 062-58-68</a>
          </li>
        </ul>
      </div>
      <div className="footer-second__bottom">
        ©2024 Cемінар-тренінг школа WTS
      </div>
    </div>
  );
};

export default Footer;
