import React from 'react';
import Header from '../../Main/Components/Header/Header';
import Footer from '../../Main/Components/Footer/Footer';

const ArticleThree = () => {
    return (
      <div className="article">
        <Header></Header>
        <div className="article__container">
          <div className="article__header">
            Основи роботи з токсичними речовинами
          </div>
          <div className="article__description">
            Навчання для працівників, які мають справу з токсичними сполуками
            (наприклад, мийні та дезінфекційні засоби, приманки для шкідників,
            реагенти тощо).
          </div>
          <div className="article__section-header">
            6 основних принципів роботи
          </div>
          <div className="article__paragraph">
            Відповідальність: <br/>На підприємстві повинна бути визначена
            відповідальна особа за роботу з токсичними сполуками, реагентами та
            засобами для миття і дезінфекції, що містять токсичні сполуки, які
            можуть потенційно загрожувати безпечності харчових продуктів. Ця
            особа повинна мати підтвердження, що вона пройшла відповідне
            навчання. Не можна допускати до роботи з токсичними сполуками
            некомпетентних працівників або осіб, що мають медичні
            протипоказання, наприклад, астму чи алергічні реакції.
          </div>
          <div className="article__paragraph">
            Облік: <br/>Підприємство повинно визначити перелік сполук або засобів
            (мийних, дезінфекуючих), які містять токсичні сполуки. Рекомендовано
            вести журнал обліку токсичних сполук та зробити приблизний
            розрахунок використання мийних та дезінфікуючих засобів на період
            (наприклад, на 14 днів, місяць тощо).
          </div>
          <div className="article__paragraph">
            Зберігання: <br/>Засоби, які містять токсичні сполуки, повинні
            зберігатися в окремому приміщенні або у зачиненій шафі у
            герметичному контейнері. Не можна зберігати токсичні сполуки у
            приміщенні разом з харчовими продуктами. Рідкі засоби у герметичних
            контейнерах можна зберігати, наприклад, під мийкою або у туалеті.
          </div>
          <div className="article__paragraph">
            Приймання: <br/>Приймання засобів, які містять токсичні сполуки, потрібно
            здійснювати окремо від харчових продуктів, таким чином, щоб потоки
            руху сировини, готової продукції та токсичних сполук не
            перетиналися. Якщо неможливо розділити ці потоки у просторі, то
            необхідно це зробити у часі, наприклад, приймати токсичні сполуки
            перед початком робочої зміни. Рекомендовано побудувати графічну
            схему з позначенням потоків руху та схем зберігання токсичних
            сполук. Невідповідні засоби (ті, що не мають супровідної
            документації, з пошкодженим пакуванням або з простроченим терміном
            використання) слід повертати постачальнику або належним чином
            маркувати та утилізувати.
          </div>
          <div className="article__paragraph">
            Робота з токсичними сполуками: <br/>Перед роботою з токсичними засобами
            слід уважно ознайомитися з інструкцією по використанню засобу від
            виробника. Рекомендовано розмістити інструкції виробника по
            поводженню з токсичними сполуками поруч з місцями зберігання або
            роботи з цими засобами. Забороняється використовувати засоби з
            простроченими строками придатності, пошкодженою упаковкою або
            засоби, що не мають інструкцій по використанню. Слід використовувати
            дезінфікуючі засоби, які дозволені до застосування Міністерством
            охорони здоров’я України (див. реєстр МОЗ). Якщо робота з токсичною
            речовиною передбачає використання засобів індивідуального захисту
            (фартух, гумові рукавички, марлева пов’язка або респіратор), то такі
            засоби повинні обов’язково використовуватись. Також поруч з місцем
            використання або застосування токсичних речовин слід зберігати
            мірний посуд (бажано калібрований). Заборонено використовувати
            токсичні дезінфікуючі засоби не за призначенням, наприклад,
            використання спиртовмісних засобів для миття підлоги у закритих
            приміщеннях (випарювання спирту у такій кількості може бути
            токсичним) або використання дуже токсичних засобів, призначених для
            дезінфекції сміттєвих контейнерів, для миття посуду. Кожен засіб для
            дезінфекції потрібно використовувати з тією концентрацією та часом
            експозиції (мінімальний час перебування дезінфекційного засобу на
            обробленій поверхні), як це рекомендує виробник. Частоту миття та
            дезінфекції можна брати з рекомендацій виробника або визначити
            самостійно, але у цьому випадку бажано мати підтвердження
            ефективності миття та дезінфекції шляхом лабораторних аналізів з
            поверхонь. Перед проведенням дезінфекції необхідно видалити всі
            механічні та харчові забруднення з поверхні.
          </div>
          <div className="article__paragraph">
            Зони прибирання: <br/> Для кожної зони повинні бути окремий інвентар для
            прибирання та ганчірки. Також повинні бути промарковані або
            розділені за кольорами та зберігатися окремо один від одного. Весь
            інвентар також потребує дезінфекції. Зберігатися в сухому вигляді
            для запобігання утворенню неприємного запаху та появі плісняви.
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
};

export default ArticleThree;