import React from 'react';

const Information = () => {
    return (
        <div className='information'>
            <div className='information__main'>
                <div className = 'information__main_text'>З 20 вересня 2019 року дотримання НАССР є обов'язковим для всіх закладів громадського харчування</div>
                {/* <div className = 'information__main_text'>За невиконання обов’язку стосовно впровадження на потужностях регулярних процедур, заснованих на принципах системи НАССР, на оператора ринку накладається штраф</div> */}
                </div>
            <div className='information__statistics'>
            <div className='information__statistics_block'>
                    <div className='information__statistics_block_number'>5</div>
                    <div className='information__statistics_block_text'>МЗП або 33500 грн. штраф для фізосіб за невиконання припису щодо усунення порушень без оформлення протоколу  </div>
                </div>
                <div className='information__statistics_block'>
                    <div className='information__statistics_block_number'>8</div>
                    <div className='information__statistics_block_text'>МЗП або 53600 грн. штраф для юросіб за невиконання припису щодо усунення порушень без оформлення протоколу </div>
                </div>
                <div className='information__statistics_block'>
                    <div className='information__statistics_block_number'>15</div>
                    <div className='information__statistics_block_text'>МЗП або 100500 грн. штраф для фізосіб за невиконання обов’язку стосовно впровадження на потужностях регулярних процедур </div>
                </div>
                <div className='information__statistics_block'>
                    <div className='information__statistics_block_number'>30</div>
                    <div className='information__statistics_block_text'>МЗП або 201000 грн. штраф для юросіб за невиконання обов’язку стосовно впровадження на потужностях регулярних процедур</div>
                </div>
                
            </div>
        </div>
    );
};

export default Information;

