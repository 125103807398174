interface Course {
  id: number | null;
  type: string;
  name: string;
  description: string;
  length: string;
  lectionTime: string;
  studentsNumber: string;
  language: string;
  price: number | string;
  program: object;
}

// export const CoursesArray: Array<Course> = [
//   {
//     id: 1,
//     name: "Доставка їжі",
//     type: "Лекція",
//     description:
//       "Лекція для тих, хто прагне дізнатися всі тонкощі організації доставки продуктів",
//     length: "1 день",
//     language: "російська",
//     lectionTime: "3 години",
//     studentsNumber: "5-7",
//     price: 750,
//     program: {
//       1: {
//         title: "Як зробити бізнес онлайн? Що потрібно знати!",
//         description: [
//           "Кроки складання бізнес–плану",
//           "Реклама",
//           "Закупівля продуктів",
//           "Продукція яку ви виготовляєте",
//           "Упаковка і доставка",
//         ],
//       },
//       2: {
//         title: "Як правильно оформити бізнес?",
//         description: [
//           "Коли потрібно оформляти бізнес",
//           "Як вибрати форму реєстрації",
//         ],
//       },
//       3: {
//         title: "Санітарні норми та правила",
//         description: [
//           "Cанітарні норми СанПін",
//           "Кухарі та харчові продукти",
//           "Санітарні норми робочого місця",
//         ],
//       },
//       4: {
//         title: "Ціноутворення",
//         description: [
//           "Hазрахунок ціни продажу",
//           "Помилки при нормативній калькуляції",
//           "Розрахунок продукції на банкет",
//         ],
//       },
//       5: {
//         title: "Сучасні тенденції в кулінарії",
//         description: [
//           "Прийоми теплової обробки продуктів",
//           "Естетика подачі страв",
//         ],
//       },
//     },
//   },
//   {
//     id: 2,
//     name: "Пташине молоко, зефір і безе",
//     type: "Майстер-клас",
//     description:
//       "Кондитерський майстерклас по приготуванню класичних десертів для початківців",
//     length: "1 день",
//     language: "українська/російська",
//     lectionTime: "3-5 годин",
//     studentsNumber: "1-5",
//     price: 1200,
//     program: {
//       1: {
//         title: "Зефір класичний та банановий",
//         description: [],
//       },
//       2: {
//         title: 'Цукерки "Пташине молоко" фісташка',
//         description: [],
//       },
//       3: {
//         title: "Безе на швейцарській та італійській мерензі",
//         description: [],
//       },
//       4: {
//         title: "Маршмеллоу класичний",
//         description: [],
//       },
//     },
//   },
//   {
//     id: 3,
//     name: "Квартет",
//     type: "Майстер-клас",
//     description:
//       "Кондитерський майстерклас по приготуванню класичних десертів для початківців",
//     length: "2 дні",
//     language: "українська/російська",
//     lectionTime: "8 годин",
//     studentsNumber: "1-5",
//     price: 2500,
//     program: {
//       1: {
//         title: 'торт "Червоний оксамит"',
//         description: ["бісквіт Червоний оксамит, кремчіз з екстрактом ванілі"],
//       },
//       2: {
//         title: 'торт "Віскі-шоколад"',
//         description: [
//           "бісквіт на основі віскі та кави, ганаш, сухе праліне, солона карамель, лохина",
//         ],
//       },
//       3: {
//         title: 'торт "Морква-апельсин"',
//         description: [
//           "морквяний бісквіт, кремчіз, апельсинове конфі, солона карамель, шоколадні хрусткі кульки",
//         ],
//       },
//       4: {
//         title: 'торт "Лавандові поля"',
//         description: ["лавандовий бісквіт, смородинове конфі, кремчіз"],
//       },
//     },
//   },

//   {
//     id: 4,
//     name: "Основи ресторанної справи",
//     type: "Лекція",
//     description:
//       "Лекція для тих, хто прагне ознайомитись з особливостями роботи ресторанного бізнесу",
//     length: "2 дні",
//     language: "російська",
//     lectionTime: "6 години",
//     studentsNumber: "5-7",
//     price: 1500,
//     program: {
//       1: {
//         title: "Сучасне поняття підприємств громадського харчування",
//         description: [
//           "Структура підприємства",
//           "Концепція ресторанного господарства, характеристика приміщень та їх облаштування",
//           "Основні фактори успіху роботи підприємства ресторанного господарства",
//           "Куточок споживача",
//         ],
//       },
//       2: {
//         title:
//           "Роль керуючого (директора) підприємства громадського харчування",
//         description: [
//           "Матеріальна відповідальність, посадови інструкція керуючого",
//           "Роль керуючого в організації обслуговування гостей",
//         ],
//       },
//       3: {
//         title: "Організація роботи персоналу торгового залу",
//         description: [
//           "Посадові інструкції працівників залу та їх функції",
//           "Облік виходу на роботу, проведення брифінгів",
//           "Вимоги до зовнішнього вигляду працівників",
//           "Підготовка торгового залу",
//           "Зустріч та розміщення гостей, подача меню, прийом замовлення, резерв стола",
//           "Подача рахунку та способи розрахунку",
//           "Конфліктні ситуації",
//           "Організація робочого місця бармена",
//         ],
//       },
//       4: {
//         title: "Організація роботи кухні",
//         description: [
//           "Посадові інструкції",
//           "Cанітарні вимоги до облаштування приміщень кухні",
//           "Аналіз та контроль руху товарів, списання продуктів",
//           "Робота з технологічними картками та збірником рецептур",
//           "Контроль за веденням журналів на кухні",
//           "Ціно утворювання в громадському харчуванні",
//           "Собівартість страв та напоїв, калькуляційні карти",
//         ],
//       },
//     },
//   },

//   {
//     id: 5,
//     name: "Відкриття пункту громадського харчування",
//     type: "Лекція",
//     description:
//       "Лекція для тих, хто самостійно планує відкрити свій заклад громадського харчування",
//     length: "2 дні",
//     language: "російська",
//     lectionTime: "6 години",
//     studentsNumber: "5-7",
//     price: 1750,
//     program: {
//       1: {
//         title:
//           "Нормативно-технологічна документація підприємств громадського харчування.",
//         description: [
//           "Технологічні карти",
//           "Калькуляційні карти",
//           "Збірник рецептур",
//           "Куточок спожижурнал перевірок",
//           "Журнал з техніки безпеки працівників",
//           "Журнал з пожежної безпеки працівників",
//           "Куточок споживача",
//         ],
//       },
//       2: {
//         title: "Ціноутворення в громадському харчуванні",
//         description: [
//           "Види і структура меню",
//           "Собівартість страв і напоїв",
//           "Види цін, порядок їх формування.",
//         ],
//       },
//       3: {
//         title: "Планування роботи ресторану",
//         description: [
//           "Вибір місця для ресторану",
//           "Інтер‘єр ресторану",
//           "Структура меню та його оформлення",
//           "Фіктивна праця з постачальниками",
//         ],
//       },
//       4: {
//         title: "Підбір персоналу",
//         description: [
//           "Cкладання анкети",
//           "Навчання працівників",
//           "Зарахування в штат",
//         ],
//       },
//     },
//   },

//   {
//     id: 6,
//     name: "Відкриття пункту громадського харчування: продвинутий рівень",
//     type: "Лекція",
//     description:
//       "Курс лекцій для тих, хто самостійно планує відкрити свій заклад громадського харчування",
//     length: "3 дні",
//     language: "українська",
//     lectionTime: "9 години",
//     studentsNumber: "5-7",
//     price: 3000,
//     program: {
//       1: {
//         title: "Загальні поняття у сфері громадського харчування",
//         description: [
//           "Поняття та особливості закладів громадського харчування",
//           "Коротка характеристика основних видів закладів громадського харчування",
//           "Структура закладу",
//           "Концепція гастрономічного бізнесу, опис приміщень та їх обладнання",
//           "Основні фактори успішної роботи закладу громадського харчування",
//           "Роль керівника (директора) у справі громадського харчування",
//           "Зонування для клієнтів",
//           "Матеріальна відповідальність та посадові інструкції керівника",
//           "Роль керівника у організації обслуговування гостей",
//         ],
//       },
//       2: {
//         title: "Реєстрація для закладів громадського харчування",
//         description: [
//           "Реєстрація фізичних осіб-підприємців та товариств з обмеженою відповідальністю",
//           "Коди видів економічної діяльності (КВЕД)",
//           "Види оподаткування",
//           "Реєстратори розрахункових операцій та портативні реєстратори розрахункових операцій",
//           "Платіжні системи",
//           "Дотримання касової дисципліни",
//           "Терміни подання податкових декларацій до фіскальної служби",
//         ],
//       },
//       3: {
//         title: "Організація роботи персоналу у торговому залі та на кухні",
//         description: [
//           "Організація роботи персоналу у торговому залі",
//           "Посадові інструкції для працівників у залі та їх функції",
//           "Реєстрація робочого часу, проведення брифінгів",
//           "Вимоги до зовнішнього вигляду працівників",
//           "Підготовка торгового залу",
//           "Зустріч та розміщення гостей, подача меню, прийом замовлень, резервування столів",
//           "Виставлення рахунку та методи розрахунків",
//           "Робота у конфліктних ситуаціях",
//           "Організація робочого місця бармена",
//           "Посадові інструкції для барменів",
//           "Санітарні вимоги до облаштування приміщень кухні",
//           "Аналіз та контроль обігу товарів, списання продуктів",
//           "Робота з технологічними картками та рецептурним збірником",
//           "Контроль за веденням журналів на кухні",
//           "Формування цін в громадському харчуванні",
//           "Види та структури меню",
//           "Витрати на страви та напої, розрахункові картки",
//           "Види ціноутворення та порядок їх формування",
//         ],
//       },
//     },
//   },
// ];

export const CoursesArray: Array<Course> = [
  {
    id: 1,
    name: "Фінанси підприємств",
    type: "Семінар",
    description: "Курс занять по фінансовій складовій роботи підприємств",
    length: "10 днів",
    language: "українська",
    lectionTime: "2 години на день",
    studentsNumber: "5-7",
    price: "уточнюється",
    program: {
      1: {
        title: "Організація грошових розрахунків підприємств",
        description: [
          "Розрахунки платіжними дорученнями",
          "Розрахунки платіжними вимогами-дорученнями",
          "Розрахунки чеками",
          "Розрахунки акредитивами",
          "Вексельна форма розрахунків",
          "Розрахунково-платіжна дисципліна та її вплив на фінансово-господарську діяльність підприємств",
        ],
      },
      2: {
        title: "Грошові надходження підприємств",
        description: [
          "Доходи (виручка) від реалізації продукції, робіт, послуг",
          "Доходи від фінансово-інвестиційної та іншої діяльності",
          "Формування валового і чистого доходу",
        ],
      },
      3: {
        title: "Формування і використання прибутку",
        description: [
          "Прибуток від реалізації продукції та його формування",
          "Методи розрахунку прибутку від реалізації",
          "Розподіл і використання прибутку. Використання чистого прибутку",
          "Сутність і методи обчислення рентабельності",
        ],
      },
      4: {
        title: "Оподаткування підприємств",
        description: [
          "Непрямі податки, які сплачують підприємства",
          "Вплив непрямих податків на фінансово-господарську діяльність підприємств",
          "Мито",
          "Акцизний податок",
          "Податок на додану вартість",
          "Збори і цільові відрахування, які сплачують підприємства",
          "Місцеві податки і збори",
        ],
      },
      5: {
        title: "Обігові кошти",
        description: [
          "Показники стану і використання обігових коштів",
          "Показники використання обігових коштів і шляхи прискорення їх обертання",
          "Вплив  обігових коштів на фінансовий стан підприємства",
        ],
      },
      6: {
        title: "Кредитування підприємств",
        description: [
          "Комерційне кредитування підприємств",
          "Лізингове кредитування підприємств",
          "Державне кредитування підприємств",
          "Кредитування підприємств за рахунок коштів міжнародних фінансово-кредитних інститутів.",
        ],
      },
      7: {
        title: "Фінансове забезпечення відтворення основних засобів",
        description: [
          "Сутність основних засобів та їх відтворення",
          "Показники стану та ефективності використання основних засобів",
          "Знос  і амортизація основних засобів",
          "Сутність і склад капітальних вкладенДжерела і порядок фінансування капітальних вкладень",
          "Фінансування ремонту основних засобів",
        ],
      },
      8: {
        title: "Оцінювання фінансового стану підприємств",
        description: [
          "Показники фінансового стану підприємства",
          "Оцінка ліквідності та платоспроможності підприємства",
          "Оцінка фінансової стійкості",
          "Комплексна оцінка фінансового стану підприємства",
        ],
      },
      9: {
        title: "Фінансове планування на підприємствах",
        description: [
          "Структура фінансового плану та порядок його складання",
          "Суть і значення оперативного фінансового плану",
        ],
      },
      10: {
        title: "Фінансова санація підприємств",
        description: [
          "План санації",
          "Сутність санаційного аудиту та етапи його проведення",
          "Аналіз виробничо-господарської діяльності підприємства, що перебуває в кризі. ",
        ],
      },
    },
  },
];
