import React, { useState, useContext} from 'react';
import {testsContext, authContext, balanceContext} from '../../../index';
import { useParams, useNavigate} from 'react-router-dom';
import { observer } from 'mobx-react';




const TestForm = () => {

  const id = useParams
  const {authStore} = useContext(authContext)
  const {testsStore} = useContext(testsContext);
  const {balanceStore} = useContext(balanceContext)

  const questions = testsStore.testArray;

  const navigate = useNavigate()


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showResult, setShowResult] = useState(false);

  const handleAnswerClick = (answer) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestion] = answer;
    setUserAnswers(newAnswers);
  };
  const handleSave = () => {
  testsStore.createCertificate(authStore.user.id);
  navigate('/cabinet')
  }

 
  const handleNextQuestion = () => {
    if (userAnswers[currentQuestion] === undefined) {
      alert("Please select an answer before proceeding.");
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
  };;

  const handleRestart = () => {
    const newBalance = balanceStore.balance - testsStore.testObj.price
    const id = localStorage.getItem('user')
    if (newBalance > 0 )
    {
    
    balanceStore.updateBalance(id, newBalance)
    setCurrentQuestion(0);
    setUserAnswers([]);
    setShowResult(false);

    }
    else {alert('мало денег!!!')}
  };

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < questions.length; i++) {
      if (userAnswers[i] === questions[i].correctAnswer) {
        score++;
      }
    }
    return score;
  };

  if (showResult) {
    const score = calculateScore();
    return (
      <div className="test-form__wrapper">
        <h2>Результати тесту</h2>
        <p>Вы вірно відповіли на {score} з {questions.length} питань.</p>
        {score > 8 ? <button  onClick = {() => handleSave()}className='button'>Друкувати сертифікат</button>
        : <button className='button' onClick={() => handleRestart()}>Пройти тест заново</button>}

      </div>
    );
  }

  const currentQuestionData = questions[currentQuestion];

  return (
    <div className="test-form__wrapper">
      <div className="test-form__inputs">
        <h3 className="test-form__question">{currentQuestionData.question}</h3>
        <ul className="test-form__options">
          {currentQuestionData.options.map((option, index) => (
            <li key={index}>
              <button className={option == userAnswers[currentQuestion] ? "test-form__option selected": "test-form__option" }  onClick={() => handleAnswerClick(option)}>
                {option}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button className="button" onClick={handleNextQuestion}>
          відповісти
        </button>
    </div>
  );
};

export default observer(TestForm);