import React from "react";
import OfferPDF from "../Offer/offer.pdf";
import Header from "../../../Main/Components/Header/Header";
import Footer from "../../../Main/Components/Footer/Footer";

const Offer = () => {
  return (
    <div className="offer">
      <Header></Header>
      <div className="offer__button_container">
        <h2 className="offer__button_name">Публічна оферта</h2>
        <a href={OfferPDF} className="offer__button">
          Завантажити PDF
        </a>
      </div>
      <div className="offer__text">
        <h3 className="offer__text_header">ДОГОВІР</h3>
        <h4 className="offer__text_header">(публічна оферта)</h4>
        <h4 className="offer__text_header">
          про надання інформаційних послуг на користь третьої особи
        </h4>
        <div className="offer__text_paragraph">
          Цей документ є офіційною публічною пропозицією (далі по тексту –
          Договір, Оферта) укласти договір про надання інформаційних послуг на
          користь третьої особи між суб’єктом господарювання, що має право на
          використання у своїй діяльності Семінар-Тренінг Школа «WTS» та
          інформація про якого зазначена на офіційному сайті:
          https://w-ts.com.ua в залежності від місця надання послуг, та який
          визначений у рахунку на сплату вартості послуг, надалі – «Виконавець»
          з однієї сторони і фізичною особою – споживачем послуг, що подала
          відповідну Заявку та дані якої зазначені у відповідній графі як
          платника, що здійснює оплату наданих послуг відповідно до умов цього
          Договору, що надалі згадується як «Замовник», яка прийняла
          (акцептувала) публічну пропозицію (оферту) про укладення даного
          Договору надавати інформаційні послуги на нижче викладених умовах:
        </div>
        <h3 className="offer__text_header">1. ЗАГАЛЬНІ ПОЛОЖЕННЯ:</h3>
        <ul>
          <li>
            1.1. Даний Договір є публічним договором у розумінні статті 633
            Цивільного кодексу України. Порядок його укладання регулюється
            статтею 642 Цивільного кодексу України. Викладення тексту цього
            Договору на веб-сторінці https://w-ts.com.ua є публічною офертою
            укладення договору на умовах, викладеному у ньому.
          </li>
          <li>
            1.2. Відповідно до ст. 642 Цивільного кодексу України даний Договір
            вважається укладеним з моменту прийняття Замовником пропозиції про
            укладення Договору (акцепт). Безумовним акцептом пропозиції
            укладення даного Договору вважається підписання та подання Заявки із
            обов’язковим заповненням всіх її розділів.
          </li>
          <li>
            1.3. Договір, укладений Замовником за допомогою акцепту публічної
            оферти, має юридичну чинність у відповідності зі ст. 642 Цивільного
            кодексу України і прирівнюється до письмово укладеного договору.
          </li>
          <li>
            1.4. Даний Договір також вважається договором приєднання у розумінні
            ч.1 ст. 634 Цивільного кодексу України, умови якого встановлені
            однією із сторін у формулярах або інших стандартних формах, який
            може бути укладений лише шляхом приєднання другої сторони до
            запропонованого Договору в цілому. Друга сторона не може
            запропонувати свої умови договору.
          </li>
          <li>
            1.5. Вчинення Замовником дій, що засвідчують його бажання укласти
            Договір, під якими у тексті цього Договору розуміється підписання та
            подання Заявки Виконавцю, підтверджує факт повного і безумовного
            прийняття (акцепт) Замовником умов цього Договору, без будь-яких
            зауважень, відповідно до ст. 642 Цивільного кодексу України.
          </li>
          <li>
            1.6. Письмовий текст цього Договору може бути наданий за запитом
            Замовника, а також може бути роздрукований Замовником самостійно з
            офіційної веб-сторінки Виконавця: https://w-ts.com.ua
          </li>
          <li>
            1.7. Акцептуючи даний Договір, Замовник підтверджує, що до укладення
            цього Договору він був повністю та належним чином ознайомлений з
            усіма його положеннями та прийняв їх із власної волі без будь-якого
            примусу.
          </li>
          <li>
            1.8. Акцептуючи даний Договір, Замовник підтверджує, що відсутні
            будь-які хвороби або вади, що можуть обмежити можливість
            корестування сайтом.
          </li>
          <li>
            1.9. Кожна Сторона гарантує іншій Стороні, що володіє необхідною
            дієздатністю, а також усіма правами та повноваженнями, необхідними
            та достатніми для укладання й виконання Договору відповідно до його
            умов. Замовник також підтверджує, що останній представляє свої
            інтереси або інтереси третіх осіб на законних підставах та не існує
            будь-яких обставин через які можна було б визнати укладений Договір
            нікчемним. Всі ризики та відповідальність із визнання цього Договору
            неукладеним покладаються на Замовника.
          </li>
        </ul>
        <h3 className="offer__text_header">2. ПРЕДМЕТ ДОГОВОРУ</h3>
        <ul>
          <li>
            2.1. Відповідно до умов даного Договору Виконавець зобов'язується
            надавати Замовнику послуги з використання Сервісу (далі – Послуги),
            без перерв, за винятком часу, для проведення необхідних ремонтних та
            регламентних робіт. Всі існуючі на даний момент Послуги, а також
            будь-який розвиток їх і/або додавання нових є предметом цього
            Договору. Замовник розуміє і погоджується з тим, що всі Послуги
            надаються за принципом "як є" (As is), і що Виконавець не несе
            відповідальності за будь-які затримки, збої, невірну або невчасну
            доставку, видалення або незбереження будь-якої персональної
            інформації.
          </li>
          <li>
            2.2. Діячо програмоми на сайті: https://w-ts.com.ua (надалі –
            «Програма») у тексті цього Договору мається на увазі комплекс
            інформаційних послуг, розроблений за спеціальною методикою
            Виконавця, що містить інформацію про основи взаємодії з сучасними
            обчислювальними та інформаційними технологіями. Етапи проходження
            програми та систему надання інформаційних послуг зазначаються
            Виконавцем на його інтернет-сторінці: https://w-ts.com.ua в
            залежності від різновиду обраного семінару.
          </li>
          <li>
            2.3. Мета надання послуг: сприяння розвитку професійних,
            інтелектуальних, творчих та комунікативних здібностей
            співробітників.
          </li>
          <li>
            2.4. Надання послуг здійснюється поетапно у групах від 8 до 10 осіб.
            Надання послуг проводиться у чітко визначені години та дні, згідно
            розкладу, відповідної до міста, який завчасно доводиться до відома
            Замовника, зокрема, шляхом зазначення його на інтернет-сторінці:
            https://w-ts.com.ua. Обмежений у часі етап надання послуг у тексті
            даного Договору надалі згадується як «заняття», а повний етап
            надання послуг, що складається із групи занять – «курс», «семінар».
          </li>
          <li>
            2.5. Тривалість одного заняття та сам формат занять (кількість
            занять на тиждень) може варіюватися в залежності від міста, в якому
            надаються послуги. Відтак, формат занять у конкретному місті
            уточнюється Виконавцем.
          </li>
          <li>
            2.6. Місце проведення занять зазначено на веб-сторінці Виконавця
            https://w-ts.com.ua відповідно до обраного міста, чи погоджується із
            Замовником окремо.
          </li>
          <li>
            2.7. Під час заповнення Заявки Замовник зазначає період, протягом
            якого бажає взяти участь у Програмі, але в межах строків Програми,
            визначених на веб-сторінці Виконавця: https://w-ts.com.ua, обравши
            попердньо курс або семинар. У разі, якщо Замовником не було вказано
            назви Програми у Заявці, вважається, що ним було обрано семінару або
            курс, за який було здійснено оплату, та назва якого може зазначатися
            у виставленому рахунку.
          </li>
          <li>
            2.8. У разі, якщо обраний період надання послуг припадає на групу,
            яка вже заповнена, – період надання послуг автоматично переноситься
            на наступний період надання послуг за аналогічною Програмою до
            групи, яка відповідає вимогам за кількістю осіб-учасників. Якщо
            Замовника з будь-яких причин не влаштовує період на який здійснено
            перенесення занять, даний Договір вважається припиненим без
            застосування штрафних санкцій до Виконавця чи Замовника.
          </li>
          <li>
            2.9. Сторони за взаємною згодою можуть домовитись про інший порядок
            надання послуг, ніж той, що передбачено у даному Договорі, зокрема,
            якщо кількість слухачів у групі є меншою ніж передбачено Виконавцем
            і група є недоукомплектованою.
          </li>
          <li>
            2.10. Якщо особа або третя особа, буде пропущено заняття, таке
            заняття може бути відпрацьовано. Формат відпрацювання (заняття по
            відео, відпрацювання індивідуально з викладачем по скайпу, заняття з
            іншою групою, тощо) узгоджується з Виконавцем, але може бути
            стягнена додаткова оплата.
          </li>
        </ul>
        <h3 className="offer__text_header">3. ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</h3>
        <ul>
          <li>
            <b> 3.1. Права Замовника:</b>{" "}
          </li>
          <li>
            3.1.1. Отримувати від Виконавця інформацію з питань організації та
            забезпечення належного рівня надання послуг.
          </li>
          <li>
            3.1.2. По завершенню Курса або Семінара (Програми) отримати
            сертифікат або свідоцтва, у разі успішної здачі тестів.
          </li>
          <li>
            3.1.3. Розірвати даний Договір в односторонньому порядку за умови
            попереднього повідомлення про це Виконавця за 2 тижденя до
            запланованого початку обраної Замовником програми.
          </li>
          <li>
            3.1.4. Самостійно обрати Програму та період, в межах, зазначених на
            веб-сторінці Виконавця (https://w-ts.com.ua), початку її проведення,
            на умовах даного Договору.
          </li>
          <li>
            3.1.5. Вимагати належного і своєчасного надання послуг Виконавцем.
          </li>
          <li>
            <b> 3.2. Обов’язки Замовника:</b>{" "}
          </li>
          <li>
            3.2.1. Своєчасно заповнити Заявку, розміщену на веб-сторінці
            Виконавця: https://w-ts.com.ua та сплатити повну вартість послуг,
            передбачену п. 4.2 даного Договору.
          </li>
          <li>
            3.2.2. Бути присутнім під час проведення заходів в межах Програми.
          </li>
          <li>
            3.2.3. Нести повну відповідальність за дії під час надання послуг, в
            тому числі, щодо дотримання техніки безпеки під час реалізації
            Програми.
          </li>
          <li>
            3.2.4. Відшкодувати збитки завдані майну Виконавця або третім
            особам, які мали місце з вини Замовника.
          </li>
          <li>
            3.2.5. Забезпечити присутність на запланованому занятті протягом
            виділених для нього годин.
          </li>
          <li>
            3.2.6. Заздалегідь інформувати Виконавця про неможливість бути
            присутньою на запланованому занятті. Замовник повністю усвідомлює,
            що пропущене заняття перенесенню чи компенсації не підлягає.
          </li>
          <li>
            3.2.7. Дотримуватись всіх правил та положень, що розміщені на
            інтернет-сторінці Виконавця: https://w-ts.com.ua.
          </li>
          <li>
            <b> 3.3. Права Виконавця:</b>{" "}
          </li>
          <li>
            3.3.1. Отримати винагороду за послуги на умовах цього Договору.
          </li>
          <li>
            3.3.2. Самостійно обирати спосіб реалізації Програми, що не може
            суперечити умовам Договору та чинному законодавству України.
          </li>
          <li>
            3.3.3. На власний розсуд залучати до виконання цього Договору третіх
            осіб.
          </li>
          <li>
            3.3.4. Встановлювати графік реалізації Програми та формувати групи.
          </li>
          <li>
            3.3.5. Переносити час проведення занять на власний розсуд з негайним
            повідомленням про це Замовника.
          </li>
          <li>
            3.3.6. Не надавати послуги, що є предметом цього Договору у разі
            відсутності належно підтвердженого факту здійснення Замовником
            оплати повної вартості послуг за цим Договором. Часткова оплата за
            цим договором не надає права Замовнику скористатися наданням послуг
            за цим Договором, якщо Сторони не домовляться про інше.
          </li>
          <li>3.4. Обов’язки Виконавця:</li>
          <li>
            3.4.1. Організувати та забезпечити належне надання послуг,
            передбачених розділом 1 цього Договору.{" "}
          </li>
          <li>
            3.4.2. Ознайомити з правилами безпеки, поведінки під час участі у
            Програмі та вимагати їх виконання.
          </li>
          <li>
            3.4.3. Враховувати індивідуальні особливості та застосовувати
            індивідуальний підхід під час проведення занять.
          </li>
        </ul>
        <h3 className="offer__text_header">4. ЦІНА ДОГОВОРУ</h3>
        <ul>
          <li>
            4.1. Ціна цього Договору дорівнює повній вартості послуг, що
            надаються Виконавцем Замовнику.
          </li>
          <li>
            4.2. Повна вартість послуг за цим Договором зазначена на
            веб-сторінці Виконавця: https://w-ts.com.ua та залежить від обраного
            курсу, семінару, Програми та локації проведення чі онлайн курсу, або
            самостійної проходження тестування.
          </li>
          <li>
            4.3. Якщо третя особа, на користь якої укладено даний Договір, із
            будь-яких причин не з’явиться на будь-яке із занять курсу Програми у
            наперед встановлений час, перерахунок вартості програми у такому
            разі не здійснюється.
          </li>
        </ul>
        <h3 className="offer__text_header">5. ПОРЯДОК ОПЛАТИ ТА РОЗРАХУНКІВ</h3>
        <ul>
          <li>
            5.1. Вартість послуг з організації та проведення Програми,
            передбаченої п. 4.2. даного Договору, сплачується Замовником, шляхом
            перерахування коштів на розрахунковий рахунок Виконавця, який
            міститься у рахунку на сплату вартості послуг, що надається
            Виконавцем Змовнику під час проходження попереднього заняття курсу
            Програми. Вартість послуг наданих у попередньому занятті курсу
            Програми входить до повної вартості послуг. У призначенні платежу
            має бути зазначено назву Програми за яку здійснюється оплата
            відповідно до назви курсу, що зазначена на веб-сайті Виконавця
            (https://w-ts.com.ua/). У разі, якщо назва курсу не буде зазначена у
            графі призначення платежу, Виконавець має право самостійно визначити
            Програму, відповідно до якої здійснено оплату, виходячи, зокрема, з
            вартості Програм та дати здійснення оплати.
          </li>
          <li>
            5.2. Виконавець не повертає кошти, сплачених Замовником за
            Договором, в разі розірвання Договору з ініціативи Замовника у
            зв’язку із пропуском занять з поважних причин, але може перенести їх
            на інші дати проведення семінару та утримаємо від 50(п’ятдесяти)% за
            письмовим зверненням Замовника.
          </li>
          <li>
            5.3. Виконавець не повертає кошти якщо, Замовник письмово не
            повідомів Виконавця за 14 (чотирнадцять) днів до початку семінару,
            курсу, та у випадку невірних відповідей при проходження тестування
            або прі закінченню часу тестування, повернення коштів може
            здійснюватися від 45 до 180 днів від отримання письмової заяви.
          </li>
          <li>
            5.4. Всі витрати пов’язані з передачею грошових коштів, включаючи
            послуги банківської установи або платіжною системою, покладаються на
            Замовника.
          </li>
          <li>
            5.5. Повна оплата вартості послуг за цим Договором має бути
            здійснена Замовником протягом 7 (семи) календарних днів з дня
            наступного за днем проведення попереднього першого заняття з
            обраного семінару Програми. Моментом здійснення оплати вважається
            день зарахування грошових коштів на розрахунковий рахунок Виконавця.
            За винятком Онлай програм та зарахуванням на власний баланс.
          </li>
          <li>
            5.6. В разі не здійснення Замовником оплати повної вартості послуг у
            встановлений п. 5.5. Договору строк Виконавець має право обмежити
            допуск до проходження курсу Програми до оплати Замовником повної
            вартості послуг та зарахуванням до власного кошелька.
          </li>
          <li>
            5.7. У разі прострочення сплати повної вартості послуг, Сторони
            розглядають кожний такий випадок індивідуально. В залежності від
            обставин та волевиявлення Сторін, третя особа, на користь якої
            Замовником було укладено даний Договір, може скористатись послугами
            у повному обсязі (якщо оплату було здійснено та підтверджено до
            запланованого часу проведення наступного заняття курсу Програми), у
            неповному обсязі без перерахування її вартості (якщо оплату було
            здійснено та підтверджено після запланованого часу проведення
            наступного заняття курсу Програми), або під час проведення
            наступного аналогічного курсу Програми. Отримані грошові кошти у
            такому разі поверненню не підлягають.
          </li>
          <li>
            5.8. У разі, якщо повна вартість послуг не буде сплачена протягом
            строків тривалості обраного семінару Програми, Договір вважається
            автоматично припиненим, а сплачені грошові кошти за цим Договором
            вважаються завдатком та відповідно до ч. 1 ст. 571 Цивільного
            кодексу України поверненню не підлягають, за виключенням випадків
            передбачених чинним законодавством України та/або за погодженням
            Сторін.
          </li>
          <li>
            5.9. Оплату можуть здійснювати тільки повнолітні особи без
            посередньо на сайті Виконавця https://w-ts.com.ua, за посиланнями на
            платіжні системи, без додаткових сплат, якщо здійснюєте оплату через
            каси банків, термінали, інтернет-банкінг, мобільні додатки, тощо усі
            відсотки та комісії за рахунок Замовника.{" "}
          </li>
        </ul>
        <h3 className="offer__text_header">
          6. ВІДПОВІДАЛЬНІСТЬ СТОРІН. ВИРІШЕННЯ СПОРІВ
        </h3>
        <ul>
          <li>
            6.1. Виконавець не несе відповідальності за невиконання умов цього
            Договору і/або за зміну програми з причин, незалежних від Виконавця,
            зокрема: через запізнення Замовника на заняття та/або діяння (дії чи
            бездіяльності) третіх сторін, що мають для Виконавця характер
            непереборної сили (зокрема, діяння органів державної влади), а також
            у разі настання форс-мажорних обставин (війна, стихійні лиха тощо).
          </li>
          <li>
            6.2. У разі прострочення здійснення оплати повної вартості послуг з
            будь-яких причин Виконавець має право стягнути Замовника пеню у
            розмірі подвійної облікової ставки Національного банку України за
            кожен день прострочення оплати від суми заборгованості.
          </li>
          <li>
            6.3. У разі умисного або з необережності нанесення шкоди майну
            Виконавця та/або власнику приміщення у якому проводитимуться
            заняття, Замовник несе повну майнову відповідальність у розмірі
            заподіяної шкоди.
          </li>
          <li>
            6.4. При наявності спорів за даним Договором, Сторони намагатимуться
            вирішити їх шляхом переговорів. При неможливості недосягнення згоди
            шляхом переговорів, спір передається зацікавленою стороною на
            розгляд суду, відповідно до правил підсудності та підвідомчості,
            встановлених чинним законодавством України
          </li>
          <li>
            6.5. На Замовника покладено відповідальність із забезпечення
            наявності на запланованому занятті згідно сформованого графіку.
          </li>
        </ul>
        <h3 className="offer__text_header">7. ІНШІ УМОВИ</h3>
        <ul>
          <li>
            7.1. Цей Договір складений українською мовою, у формі публічного
            договору приєднання.{" "}
          </li>
          <li>
            7.2. Недійсність окремого положення цього Договору не впливає на
            недійсність цього Договору в цілому
          </li>
          <li>
            7.3. Жодна із Сторін не може переуступити свої права та обов’язки за
            Договором третім особам.
          </li>
          <li>
            7.4. Виконавець залишає за собою право внести зміни в умови Договору
            і/або відкликати Договір у будь-який момент на свій розсуд. У разі
            внесення Виконавцем змін в Оферту, такі зміни вступають в силу з
            моменту розміщення відповідного повідомлення на сайті.
          </li>
          <li>
            7.5. Замовник погоджується і визнає, що внесення змін до Договору
            автоматично тягне за собою внесення цих змін і до укладеного і
            діючого між Замовником та Виконавцем Договору.
          </li>
          <li>
            7.6. У разі відкликання Оферти Виконавцем протягом терміну дії
            Договору, Договір вважається припиненим з моменту відкликання, якщо
            інше не обумовлено Виконавцем при відкликанні Оферти.
          </li>
          <li>
            7.7. Договір може бути розірваний за згодою Сторін в будь-який час
            або з інших підстав, передбачених цією Офертою і/або чинним
            законодавством України.
          </li>
          <li>
            7.8. Акцептуючи даний Договір, згідно з умовами Закону України «Про
            захист персональних даних» Замовник, а також третя особа, від імені
            якої він виступає, як суб’єкт персональних даних, дає однозначну
            згоду на використання та обробку усіма можливими способами
            уповноваженими особами Виконавця персональних даних Замовника та
            особи, від імені якої він діє, що надані Замовником та/або зазначені
            у даному Договорі, а також осіб, зазначених у Заявці, з метою
            забезпечення реалізації відносин у сфері культури, дозвілля,
            соціальної діяльності, у сфері бухгалтерського та податкового
            обліку, рекламування, анонсування та інших цілях встановлених
            Виконавцем. Суб’єкта персональних даних повідомлено про володільця,
            мету збору, склад та зміст зібраних персональних даних, права такого
            суб’єкта, визначені законодавством та осіб, яким передаються його
            персональні дані.
          </li>
          <li>
            7.9. Акцептуючи даний Договір, Замовник надає свою згоду на
            здійснення фото-, відеозйомок за участі особи, від імені якої він
            діє та/або себе, на використання фото, відеоматеріалів, із
            зображенням особи, від імені якої він діє та/або себе, а також
            створення і використання фото-, відеоматеріалів, які будуть створені
            в процесі відвідування Програми курсу, будь-яким незабороненим
            законом способом (у будь-якій формі та обсязі) без обмеження по
            термінам і території використання (з правом передачі третім особам).
          </li>
          <li>
            7.10. Сторони домовились, що окремого Акту прийому-передачі на
            підтвердження надання послуг за цим Договором не вимагається.
            Послуги вважаються наданими у повному обсязі та належним чином, якщо
            протягом строку тривалості Програми та після семи календарних днів з
            дня наступного за днем у який було проведено останнє заняття згідно
            графіку занять, Виконавцем не було отримано письмової мотивованої
            претензії щодо якості надання послуг.
          </li>
          <li>
            7.11. Договір набуває чинності з моменту здійснення Замовником
            повної оплати вартості послуг, відповідно до розділу 5 цього
            Договору та діє до повного виконання Сторонами взятих на себе
            зобов’язань.
          </li>
        </ul>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Offer;
