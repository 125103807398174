import React from "react";
import Header from "../Main/Components/Header/Header";
import Footer from "../Main/Components/Footer/Footer";
import Course from "./Components/Course/Course";

import { CoursesArray } from "../../Arrays/CoursesArray";
const Courses = () => {
  const courses = CoursesArray;

  const array = [
    {
      id: 1,
      name: "Фінанси підприємств",
      type: "Семінар",
      description: "Курс занять по фінансовій складовій роботи підприємств",
      length: "10 днів",
      language: "українська",
      lectionTime: "2 години на день (20 годин загалом)",
      studentsNumber: "5-7",
      price: 100000,
      program: {
        1: {
          title: "Організація грошових розрахунків підприємств",
          description: [
            "Розрахунки платіжними дорученнями",
            "Розрахунки платіжними вимогами-дорученнями",
            "Розрахунки чеками",
            "Розрахунки акредитивами",
            "Вексельна форма розрахунків",
            "Розрахунково-платіжна дисципліна та її вплив на фінансово-господарську діяльність підприємств",
          ],
        },
        2: {
          title: "Грошові надходження підприємств",
          description: [
            "Доходи (виручка) від реалізації продукції, робіт, послуг",
            "Доходи від фінансово-інвестиційної та іншої діяльності",
            "Формування валового і чистого доходу",
          ],
        },
        3: {
          title: "Формування і використання прибутку",
          description: [
            "Прибуток від реалізації продукції та його формування",
            "Методи розрахунку прибутку від реалізації",
            "Розподіл і використання прибутку. Використання чистого прибутку",
            "Сутність і методи обчислення рентабельності",
          ],
        },
        4: {
          title: "Оподаткування підприємств",
          description: [
            "Непрямі податки, які сплачують підприємства",
            "Вплив непрямих податків на фінансово-господарську діяльність підприємств",
            "Мито",
            "Акцизний податок",
            "Податок на додану вартість",
            "Збори і цільові відрахування, які сплачують підприємства",
            "Місцеві податки і збори",
          ],
        },
        5: {
          title: "Обігові кошти",
          description: [
            "Показники стану і використання обігових коштів",
            "Показники використання обігових коштів і шляхи прискорення їх обертання",
            "Вплив  обігових коштів на фінансовий стан підприємства",
          ],
        },
        6: {
          title: "Кредитування підприємств",
          description: [
            "Комерційне кредитування підприємств",
            "Лізингове кредитування підприємств",
            "Державне кредитування підприємств",
            "Кредитування підприємств за рахунок коштів міжнародних фінансово-кредитних інститутів.",
          ],
        },
        7: {
          title: "Фінансове забезпечення відтворення основних засобів",
          description: [
            "Сутність основних засобів та їх відтворення",
            "Показники стану та ефективності використання основних засобів",
            "Знос  і амортизація основних засобів",
            "Сутність і склад капітальних вкладенДжерела і порядок фінансування капітальних вкладень",
            "Фінансування ремонту основних засобів",
          ],
        },
        8: {
          title: "Оцінювання фінансового стану підприємств",
          description: [
            "Показники фінансового стану підприємства",
            "Оцінка ліквідності та платоспроможності підприємства",
            "Оцінка фінансової стійкості",
            "Комплексна оцінка фінансового стану підприємства",
          ],
        },
        9: {
          title: "Фінансове планування на підприємствах",
          description: [
            "Структура фінансового плану та порядок його складання",
            "Суть і значення оперативного фінансового плану",
          ],
        },
        10: {
          title: "Фінансова санація підприємств",
          description: [
            "План санації",
            "Сутність санаційного аудиту та етапи його проведення",
            "Аналіз виробничо-господарської діяльності підприємства, що перебуває в кризі. ",
          ],
        },
      },
    },
  ];

  console.log(courses);
  return (
    <div className="courses">
      <Header></Header>
      <div className="courses__info">
        <h5 className="courses__info_header">
          Актуальні курси, лекції і вебінари
        </h5>
        <div className="courses__info_description">
          На цій сторінці ви знайдете список всіх акуальних заходів в нашій
          школі
        </div>
      </div>
      <div className="courses__container">
        {array.map((el) => (
          <Course key={el.id} courseObj={el}></Course>
        ))}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Courses;
