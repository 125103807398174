import React, {useContext} from 'react';
import CreatePDF from './CreatePDF';
import { observer } from 'mobx-react';
import { PDFViewer } from '@react-pdf/renderer';
import { authContext } from '../..';

const PDFPage = () => {



    const {authStore} = useContext(authContext)

    const test = authStore.testsDone[0]
  
    // console.log(test)

    return (
    <div>
    <PDFViewer style={{width: '100%',
    height: '100vh'}}>
      <CreatePDF></CreatePDF>
        <CreatePDF bearer = {test.bearer} testName = {test.testName}/>
      </PDFViewer>
    </div>
    );
};

export default observer(PDFPage);