import React from "react";

const SorryPopup = () => {
  return (
    <div className="sorry-form">
      <header className="sorry-form__header">
        {" "}
        Нажаль, наразі реєстрація на семінар закрита
      </header>

      <div className="sorry-form__container">
        <div className="sorry-form__container_text">
          За додатковою інформацією звертайтесь по номеру
        </div>
        <a href="tel:+380980625668" className="sorry-form__container_contact">
          +38 (098) 062-58-68
        </a>
        {/* <div className="contact-form__container-second_image"></div> */}
      </div>
    </div>
  );
};

export default SorryPopup;
