import React from "react";
import { Link } from "react-router-dom";

const Card = ({ courseObj }) => {

  const {
    id,
    name,
    description,
    type,
    program,
    lectionTime,
    length,
    studentsNumber,
  } = courseObj;

  return (
    <div className="carousel-card">
      <div className="carousel-card__main-info">
        <div className="carousel-card__main-info_type">{type}</div>
        <div className="carousel-card__main-info_header">{name}</div>
        <div className="carousel-card__main-info_description">
          {description}
        </div>
        <Link
          to={`/course/${id}`}
          className="carousel-card__main-info_button"
        >
          <button className=" button"> Більше інформації</button>
        </Link>
      </div>
      <div className="carousel-card__blocks">
        <div className="carousel-card__block">{length}</div>
        <div className="carousel-card__block">{lectionTime}</div>
        <div className="carousel-card__block">{studentsNumber} cтудентів</div>
      </div>
      <div className="carousel-card__description">
        <div className="carousel-card__description_header">
          Программа:
        </div>
        <ul className="carousel-card__description_container">
          {Object.keys(program).map((key) => (
            <li className="carousel-card__description_section">
              {program[key].title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Card;
