import {makeAutoObservable} from "mobx";   
import {TestsArray} from '../Arrays/TestsArray'
import { TestsService } from "../services/TestsService";




export default class TestsStore {
    user = {};
    header = ''
    testObj = {};
    testArray = [];

    constructor() {
        makeAutoObservable(this);
    }
    


    setArray(param) {
            this.header = TestsArray[param-1].testName
            this.testObj = TestsArray[param-1]
            this.testArray = TestsArray[param-1].testQuestions

    }


    setUser(user) {
        this.user = user
    }


    async createCertificate(id) {
        const bearer = `${this.user.lastName} ${this.user.firstName}`
        const testdata = {
            bearer,
            testName: this.header
        }
        await TestsService.createSertificate(id, testdata)

    }
}

