import {makeAutoObservable} from "mobx";   
import { UserService } from "../services/UserService";



export default class BalanceStore {
    balance = null;

    constructor() 
    {
        makeAutoObservable(this);
    }



    setBalance(number) {
        this.balance = number
        console.log(this.balance)
    }
   
    async getBalance(id) {
        
        try {
            const response = await UserService.getBalance(id)
            this.balance = response.data;
            console.log(response)
        }
        catch(e) {
            console.log(e)
        }
    }

    async updateBalance(id, balance) {
        
        try {
            const response = await UserService.updateBalance(id, balance)
            this.setBalance(response.data.balance)
            console.log(this.balance)


        }
        catch(e) {
            console.log(e)
        }
        
    }
}

