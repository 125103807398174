import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../../Pages/Login/LoginForm';

const WarningPopup = ({showWarning}) => {

    const [isLogin, showLogin] = useState(false)

    return (
        <div className='warning' onClick={() => showWarning()}>
        
            {!isLogin && 
            <div className='warning__container'>
                <div className='overlay' onClick={() => showWarning()}></div>
                <div className='warning__main' onClick =  {(event) => event.stopPropagation()}>
                <div className='warning__button_close' onClick={() => showWarning()}>x</div>
                <div className='warning__text'> Для продовження необхідно авторизуватись на сайті</div>
                <div className='warning__buttons'>
                <div className='button' onClick = {() => showLogin(!isLogin)}>Логін</div>
                <Link to = {'/auth/registration'}><div className='button'>Реєстрація</div></Link>
                </div>
                </div>
            </div>
            
            }
            {isLogin && <div className='warning__container'>
            <div className='overlay' onClick={() => showWarning()}></div>
                <div className='warning__main'> 
                <LoginForm/> 
                </div>
                </div>}
        </div>
    );
};

export default WarningPopup;