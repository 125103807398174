import React from 'react';
import { Link } from 'react-router-dom';

const ArticleSection = ({title, articles}) => {
    return (
        <div className='articles__main'>
                <h2 className='articles__main_header'>{title}</h2>
                <div className='articles__main_container'>
                {articles && articles.map(el => 
                      <div className='articles__main_link'>
                    <Link to = {el.link}>
                      <h2 className='articles__main_link_header'>{el.linkTitle}</h2>
                      <div className='article__main_link_description'>{el.linkDescription}</div>
                      </Link></div>  
                )}   
                                     
                </div>
            </div>
    );
};

export default ArticleSection;