import React from 'react';

const Test = ({header, description, time, level, price}) => {
    return (
        <div className='test'>
            <div className='test__main'>
                <div className='test__main_header'>{header}</div>
                <div className='test__main_description'>{description}</div>
            </div>
            <div className='test__info'>
            <div className='test__info_time'>Час: {time}</div>
            <div className='test__info_level'>Рівень: {level}</div>
            <div className='test__info_price'>Ціна: {price} грн.</div>
            </div>
        </div>
    );
};

export default Test;