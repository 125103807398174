import React, {useRef} from 'react';
import Footer from '../Main/Components/Footer/Footer';
import Header from '../Main/Components/Header/Header';
import ArticleHeader from './Components/ArticleHeader/ArticleHeader';
import ArticleSection from './Components/ArticleSection/ArticleSection';
import { ArticlesArray } from '../../Arrays/ArticlesArray';
import { LinksArray } from '../../Arrays/LinksArray';

const Articles = () => {

    const refOne = useRef(null)
    const refTwo = useRef(null)

    const handleClick = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      };


    return (
        <div className='articles'>
            <Header></Header>
            <div className='articles__container'>
            
            <div className='articles__header'>
                <div className='articles__header_text'>
                <h1 className='articles__header_text_main'>Корисні матеріали і посилання</h1>
               <div className='articles__header_text_desription'></div>
               </div>
                <div className='articles__header_links'>
                <div className='articles__header_link' onClick={() => {
                    handleClick(refTwo)
                }}>Основні законодавчі акти</div>
                <div className='articles__header_link'>Статті по ХАССП</div>
                <div className='articles__header_link' onClick={() => {
                    handleClick(refOne)
                }}>Інформація для працівників</div>
                </div>

            </div>
            <div ref = {refOne}></div>
            <ArticleSection  title = {'Матеріли для підготовки до тестування'} articles = {ArticlesArray}></ArticleSection>
            <div ref = {refTwo}></div>
            <ArticleSection title ={'Основні законодавчі акти'} articles = {LinksArray}></ArticleSection>
            {/* <div ref = {refTwo}></div>
            <ArticleSection  title = {'Посилання на основні законодавчі акти'}></ArticleSection> */}
        </div>
        <Footer></Footer>
        </div>
    );
};

export default Articles;

