
  
interface Article {
    linkId: number | null;
    link: string;
    linkTitle: string
    linkDescription: string;
  }


  export const ArticlesArray: Array<Article> = [
    {
        linkId: 1,
        link: '/articles/article-basics',
        linkTitle: 'Базова інформація для ознайомлення з HACCP',
        linkDescription: 'Інформація для офіціантів, працівників служби доставки, обслуговуючого персоналу та інших працівників, які не здійснюють прямий контакт з продуктами харчування або процесом їх приготування'
    },
    {
        linkId: 2,
        link: '/articles/article-advanced',
        linkTitle: 'Матеріли для поглиблення знань по HАССP',
        linkDescription: 'Інформація для працівників, що задіяні у розробці та впроваджені системи харчової безпеки'
    },
    {
        linkId: 3,
        link: '/articles/article-toxics',
        linkTitle: 'Основи роботи з токсичними речовинами',
        linkDescription: 'Інформація для працівників, які мають справу з токсичними сполуками (наприклад, мийні та дезінфекційні засоби, приманки для шкідників, реагенти тощо).'
    }
]