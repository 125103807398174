import React, {useContext} from 'react';
import Footer from '../Main/Components/Footer/Footer';
import Header from '../Main/Components/Header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { authContext } from '../..';
import { observer } from 'mobx-react';



const AuthPage = () => {


const {authStore} = useContext(authContext)

const navigate = useNavigate()

if(authStore.isAuth) {navigate('/')};

    
    return (
        <div className='auth'>
        <Header></Header>
        <div className = 'auth__container'>
            <Outlet/>
        </div>
        <Footer></Footer>
        </div>
    );
};

export default observer(AuthPage);