import React, { useEffect, useState } from "react";
import "./certificates.scss";
import CertificateItem from "./CertificateItem";
import ErrorPopup from "./ErrorPopup";

// Интерфейс для описания данных сертификата
interface Certificate {
  id: number;
  seria: string;
  name: string;
  number: string;
  date: string;
  bearer?: string;
  description: string;
}
const initialCertificates: Certificate[] = [
  {
    id: 2127429,
    seria: "АА",
    number: "АА № 2127429/38",
    date: "29.08.2021",
    bearer: "Д. Вероніка лександрівна",
    description: "00-00.08.2021 р.",
    name: '"Основи ресторанного бізнесу"',
  },
  {
    id: 2127346,
    seria: "АБ",
    number: "АБ № 2127346",
    date: "25.08.2023",
    bearer: "С. Тетяна Анатоліївна",
    description: "07-25.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"',
  },
  {
    id: 2127347,
    seria: "АБ",
    number: "АБ № 2127347",
    date: "25.08.2023",
    bearer: "С. Вікторія Миколаївна",
    description: "07-25.08.2023 р., 30 год., 1 кредит (ЄКТС) ",
    name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"',
  },
  {
    id: 2127348,
    seria: "АБ",
    number: "АБ № 2127348",
    date: "25.08.2023",
    bearer: "М. Марина Ігорівна",
    description: "07-25.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Організації виробництва, сервісу та обслуговування в закладах ресторанного господарства"',
  },
  {
    id: 2127349,
    seria: "АБ",
    number: "АБ № 2127349",
    date: "05.09.2023",
    bearer: "П. Оксана Іванівна",
    description: "25.08-05.09.2023 р., 15 год 0,5 кредити (ЄКТС)",
    name: '"Санітарія то гігієна"',
  },
  {
    id: 2127350,
    seria: "АА",
    number: "АА № 2127350",
    date: "04.08.2023",
    bearer: "С. Вікторія Миколаївна",
    description: "17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Технологія виробництва кулінарної продукції"',
  },
  {
    id: 2127351,
    seria: "АА",
    number: "АА № 2127351",
    date: "04.08.2023",
    bearer: "С. Тетяна Анатоліївна",
    description: "17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Технологія виробництва кулінарної продукції"',
  },
  {
    id: 2127352,
    seria: "АА",
    number: "АА № 2127352",
    date: "04.08.2023",
    bearer: "М. Марина Ігорівна",
    description: "17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Технологія виробництва кулінарної продукції"',
  },
  {
    id: 2127353,
    seria: "АА",
    number: "АА № 2127353",
    date: "04.08.2023",
    bearer: "П. Оксана Іванівна",
    description: "17.07-04.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Технологія виробництва кулінарної продукції"',
  },
  {
    id: 2127359,
    seria: "АБ",
    number: "АБ № 2127359",
    date: "02.08.2023",
    bearer: "Ф. Наталія Іванівна",
    description: "12.07-02.08.2023 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Устаткування закладів ресторанногогосподарства"',
  },
  {
    id: 2127365,
    seria: "АБ",
    number: "АБ № 2127365",
    date: "22.08.2023",
    bearer: "Ф. Наталія Іванівна",
    description: "08-22.08.2023 р., 18 год., 0,6 кредит (ЄКТС)",
    name: '"Особливості кухонь народів світу"',
  },
  {
    id: 2127355,
    seria: "АА",
    number: "АА № 2127355",
    date: "29.03.2024",
    bearer: "М. Михайло Михайлович",
    description: "14-29.03.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)",
    name: '"Інноваційні методи математичного моделювання та нові можливості використання блокчейн технологій у системах управління якістю"',
  },
  {
    id: 2127358,
    seria: "АА",
    number: "АА № 2127358",
    date: "15.04.2024",
    bearer: "C. Володимир Павлович",
    description: "01-15.04.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)",
    name: '"Особливості експлуатації і технічного сервісу технологічного обладнання харчових виробництв"',
  },
  {
    id: 2127360,
    seria: "АА",
    number: "АА № 2127360",
    date: "15.04.2024",
    bearer: "П. Ігор Павлович",
    description: "01-15.04.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)",
    name: '"Особливості експлуатації і технічного сервісу технологічного обладнання харчових виробництв"',
  },
  {
    id: 2127368,
    seria: "АБ",
    number: "АБ № 2127368",
    date: "27.08.2024",
    bearer: "С. Мирослава Іванівна",
    description: "07-27.08.2024 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Організація виробництва, сервісу та обслуговування в закладах ресторанного господарства"',
  },
  {
    id: 2127407,
    seria: "АБ",
    number: "АБ № 2127407",
    date: "27.08.2024",
    bearer: "П. Юрій Анатолійович",
    description: "07-27.08.2024 р., 30 год., 1 кредит (ЄКТС)",
    name: '"Організація виробництва, сервісу та обслуговування в закладах ресторанного господарства"',
  },
  {
    id: 2127357,
    seria: "АВ",
    number: "АВ № 2127357",
    date: "05.11.2024",
    bearer: "Б. Роман Анатолійович",
    description: "04.10-05.11.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)",
    name: '"Курс для керівників та членів групи НАССР(ХАССП)"',
  },
  {
    id: 2127406,
    seria: "АА",
    number: "АА № 2127406",
    date: "16.08.2024",
    bearer: "М. Марина Ігорівна",
    description: "12.07-16.08.2024 р., 45 ГОД., 1,5 КРЕДИТИ (ЄКТС)",
    name: '"Технологічні розрахунки, облік і звітність на підприємствах громадського харчування"',
  },
  {
    id: 2127361,
    seria: "АВ",
    number: "АВ № 2127361",
    date: "05.11.2024",
    bearer: "П. Юрій Анатолійович",
    description: "04.10-05.11.2024 р., 90 ГОД., 3 КРЕДИТИ (ЄКТС)",
    name: '"Курс для керівників та членів групи НАССР(ХАССП)"',
  },
];

const CertificatesTable: React.FC = () => {
  const [seria, setSeria] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [certificate, setCertificate] = useState<Certificate[]>([]);
  const [error, setError] = useState<Boolean>(false);

  // useEffect(() => {
  //   console.log(seria);
  // }, [seria]);

  const FindCertificate = (event: any) => {
    console.log("отправлено");
    event.preventDefault();
    const newArray = initialCertificates.filter((obj) =>
      obj.seria.toString().startsWith(seria)
    );
    const sortedNewArray = newArray.filter((obj) =>
      obj.id.toString().startsWith(number)
    );
    seria.length > 0 ? setCertificate(sortedNewArray) : setCertificate([]);

    sortedNewArray.length === 0 && setError(!error);

    console.log(error);
  };

  const onClose = () => {
    setError(!error);
  };

  return (
    <div className="certificate-list__container">
      {error && <ErrorPopup onClick={onClose}></ErrorPopup>}
      <h1 className="certificate-list__header">Перевірка сертифікату</h1>
      <form className="certificate__inputs">
        <div className="input__container">
          <div className="input_name">Серія</div>
          <input
            placeholder="АА"
            maxLength={2}
            onChange={(event) => setSeria(event.target.value.toUpperCase())}
            value={seria}
            required
            className="input seria"
          ></input>
        </div>

        <div className="input__container">
          <div className="input__name">Номер</div>
          <input
            placeholder="1234567"
            minLength={7}
            required
            onChange={(event) => setNumber(event.target.value)}
            value={number}
            className="input"
          ></input>
        </div>
        {number.length < 7 ? (
          <button
            type="submit"
            className="certificate__button disabled"
            disabled
          >
            Перевірити
          </button>
        ) : (
          <button
            type="submit"
            className="certificate__button"
            onClick={(event) => FindCertificate(event)}
          >
            Перевірити
          </button>
        )}
      </form>
      <div className="certificate-list__table">
        {certificate.map((el) => (
          <CertificateItem
            id={el.id}
            name={el.name}
            number={el.number}
            date={el.date}
            bearer={el.bearer}
            description={el.description}
          ></CertificateItem>
        ))}
      </div>
    </div>
  );
};

export default CertificatesTable;
