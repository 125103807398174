import React from 'react';
import styled from 'styled-components'

const StyledContainer = styled.a`
min-width: 330px;
max-width: 500px;
min-height: 300px;
border: 1px solid rgb(42,44,77);
margin: 10px;
margin-top: 30px;
flex-grow: 1;
cursor: default;
display: flex;
justify-content: space-around;
padding: 10px;
padding-top: 30px;
align-items: center;
flex-direction: column;

`

const Program = ({program, header, description}) => {

     
    return (
    <StyledContainer href = {program}  >
       <h3 className='program__header'>{header}</h3>
        {/* <div className='program__description'>{description}</div> */}
        <a  className ='program__button' href = {program} > Переглянути</a>
        </StyledContainer>
    );
};

export default Program;