import React from 'react';

const TestsHeader = () => {
    return (
        <div className='tests-header__container'>

            <div className='tests-header__text'>Хочеш перевірити свої знання та отримати сертифікат?</div>
            <div className='tests-header__text'>Якраз для цього ми підготували декілька простих тестів</div>
            <div className='tests-header__text'>Один тест займає не більше 30 хвилин</div>
            <div className='tests-header__text'>Для успішного проходження вистачить всього 85% правильних відповідей </div>
        </div>
    );
};

export default TestsHeader;