import React, { useState, useEffect, useContext, useDebugValue } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import TestForm from "./TestForm";
import { testsContext, authContext, balanceContext } from "../../../index";
import { observer } from "mobx-react";
import Header from "../../Main/Components/Header/Header";
import Footer from "../../Main/Components/Footer/Footer";
import WarningPopup from "../../../CommonComponents/WarningPopup/WarningPopup";

const TestPage = () => {
  const [isBought, setBought] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const [warning, showWarning] = useState(false);

  const id = useParams();

  const { authStore } = useContext(authContext);
  const { testsStore } = useContext(testsContext);
  const { balanceStore } = useContext(balanceContext);

  const handleWarning = () => {
    alert('функція ще у розробці')
    showWarning(!warning)
    console.log(warning)
  }
  

  const handleClick = () => {
     console.log(authStore.isAuth)
    const newBalance = balanceStore.balance - testsStore.testObj.price;
    if (newBalance > 999999999999) {
      const id = localStorage.getItem("user");
      balanceStore.updateBalance(id, newBalance);
      setBought(!isBought);
    } else alert("функція ще у розробці");
  };

  useEffect(() => {
    testsStore.setUser(authStore.userInfo);
    testsStore.setArray(id.id);
    if(localStorage.getItem('isBought') != null && id.id == localStorage.getItem('isBought')[0])
    {setBought(true)}
  }, [authStore.userInfo]);

  return (
    <div className="testPage">
      {warning && <WarningPopup showWarning={handleWarning()}/>}
      <Header></Header>

      
      {!isBought && 
        <div className="testPage__container">
          <div className="testPage__header_container">
          
            <div className="testPage__header_text">
              {testsStore.testObj.testName}
            </div>
            <div className="testPage__header_description">
              {testsStore.testObj.testDescription}
            </div>
            {/* {authStore.isAuth ? (
              <button className="testPage__button" onClick={() => handleClick()}>
                Сплатити і пройти
              </button>
            ) : (
              <button
                className="testPage__button"
                onClick={() => handleWarning()}
              >
                Сплатити і пройти
              </button>
            )} */
            
            <button className="testPage__button" onClick={() => handleClick()}>
                Сплатити і пройти
              </button>
              
            }
          </div>
          <div className="testPage__cards">
            <div className="testPage__card">
              Орієнтовний час на прохоження: {testsStore.testObj.time}
            </div>
            <div className="testPage__card">
              Вартість: {testsStore.testObj.price} грн.
            </div>
            <div className="testPage__card">
              Рівень необхідних знань: {testsStore.testObj.level}
            </div>
          </div>
        </div>
      }

      {!isBought && (
        <div className="testPage__warning_container">
          <div>
            {testsStore.testObj.articleLink && (
              <div className="testPage__warning_link">
                Перед проходженням тесту радимо ознайомитись з матеріалами по
                темі <Link
                  style={{ color: "blue" }}
                  to={`${testsStore.testObj.articleLink}`}
                >за посиланням</Link>
              </div>
            )}
          </div>
          <div
            onClick={() => setHidden(!isHidden)}
            className="testPage__warning_button"
          >
            {" "}
            Додаткова інформація*
          </div>
          {!isHidden && (
            <div className="testPage__warning">
              <span className="testPage__warning_item">
                *Натискаючи 'cплатити і пройти ' ви погоджуєтесь з <Link>умовами використання </Link>
                  веб-сайту
              </span>
              <span className="testPage__warning_item">
                **Одна оплата дозволяє пройти тест лише один раз. У разі, якщо
                ви отримаєте меньше 85% правильних відповідей, сертифікат не
                надається
              </span>
              <span className="testPage__warning_item">
                ***Після прохождения тесту електронна версія сертифікату з'явиться в особистому кабінеті. Для отримання друкованного сертифіката ознайомтесь з <Link to = ''> умовами доставки.</Link>
              </span>
              <span className="testPage__warning_item">
                ****Не перезавантажуйте сторінку під час проходження тесту. Це
                може призвести до втрати всього прогресу
              </span>
            </div>
          )}
        </div>
      )}
      {isBought && <TestForm></TestForm>}
      <Footer></Footer>
    </div>
  );
};

export default observer(TestPage);
