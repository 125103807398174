import React from "react";

const PaymentPopup = () => {
  return (
    <div className="payment-form">
      <header className="payment-form__header">Реквізити для оплати</header>

      <div className="payment-form__container">
        <div className="payment-form__container_text">
          Код отримувача: 2759000035
        </div>
        <div className="payment-form__container_text">
          Рахунок отримувача: UA493052990000026002045013268
        </div>
        <div className="payment-form__container_text">
          Назва банку: АТ КБ "ПРИВАТБАНК"
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;
