import {api} from '../http/index'

class UserService {
    
    static async createUserInfo() {
        try {
        return api.post('/createUserInfo', {balance: 0, testsDone: []})}
        catch(e) {
            console.log('ошибка')
        }
    }

    static async updateBalance(id, balance) {
        try {
            return api.post('/updateBalance', {id, balance})}

        catch(e) {
        
        }
    }

    static async getBalance(id) {
        try {
            return api.post('/getBalance', {id})}

        catch(e) {
        
        }
    }


  
}

export {UserService}