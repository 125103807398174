import React from "react";
import Header from "../../Main/Components/Header/Header";
import Footer from "../../Main/Components/Footer/Footer";

const ArticleOne = () => {
  return (
    <div className="article">
      <Header></Header>
      <div className="article__container">
        <div className="article__header">
           Інформація для ознайомлення з основними концепціями HACCP
        </div>
        <div className="article__description">
          Для офіціантів, працівників служби доставки, обслуговуючого персоналу
          та інших працівників, які не здійснюють прямий контакт з продуктами
          харчування або процесом їх приготування. Мінімальні необхідні знання
          для початку роботи.
        </div>
        <div className="article__paragraph">
          20 вересня 2016 року набув чинності розділ VII Закону України «Про
          основні принципи та вимоги до безпечності та якості харчових
          продуктів», який регламентує гігієнічні вимоги щодо поводження з
          харчовими продуктами. Згідно цього Закону, всі оператори ринку
          харчових продуктів (ресторани, магазини, кафе, склади та будь-які інші
          підприємства, які задіяні на будь-якому етапі виробництва,
          транспортування або обігу харчових продуктів), повинні впровадити
          систему харчової безпеки HACCP."
        </div>
        <div className="article__paragraph">
          Контроль над дотриманням даної норми покладено на Держпродспоживсужбу
          (Державну Службу України з Питань Безпечності Харчових Продуктів та
          Захисту Споживачів).
        </div>
        <div className="article__paragraph">
          "HACCP (ХАССП) – це система аналізу ризиків, небезпечних чинників і
          контролю критичних точок. Важливо зазначити, що система HACCP
          стосується лише безпечності харчових продуктів, а не їх якості або
          корисності. Впровадження системи HACCP підприємство може здійснити
          самостійно або з допомогою консультантів. У будь-якому разі
          відповідальність за безпечність харчових продуктів несе підприємство –
          оператор ринку. Сертифікація системи не є обов'язковою і не
          вимагається."
        </div>
        <div className="article__paragraph">
          На кожному підприємстві потрібно створити групу HACCP, яка буде
          відповідати за різні аспекти впровадження системи. Керівник групи
          HACCP повинен бути відповідальний за дієвість системи безпечності
          харчових продуктів. Керівником групи може бути шеф-кухар, директор
          (для малих підприємств) або дієтична медична сестра (у навчальних
          закладах).
        </div>
        <div className="article__paragraph">
          Під час впровадження системи HACCP підприємство – оператор ринку
          повинно уважно проаналізувати всі небезпечні фактори, їх імовірність
          та наслідки для здоров’я, які можуть бути присутні у харчовому
          продукті: біологічні (наприклад, гельмінти, лістерія, сальмонела),
          хімічні (цезій, стронцій, ртуть) та фізичні (скло, частини
          обладнання). Після цього треба обрати належні заходи контролю над цими
          небезпеками, які мінімізують ризики виготовлення або реалізації
          небезпечного продукту.
        </div>
        <div className="article__paragraph">
          Виробники харчових продуктів та заклади громадського харчування
          повинні належним чином маркувати алергени, присутні у харчовому
          продукті. Спосіб маркування та перелік алергенів зазначені у Законі
          України «Про інформацію для споживачів щодо харчових продуктів».
          Навіть якщо ви не є виробником харчового продукту, ви все одно несете
          відповідальність за належне маркування продуктів, які реалізовуєте,
          тому під час прийому або закупівлі харчових продуктів потрібно
          контролювати не лише строки придатності, зовнішній вигляд та запах,
          але і наявність належного маркування
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ArticleOne;
