import React from 'react';
import LoginForm from '../../Pages/Login/LoginForm';

const PopupForm = ({child}) => {
    return (
        <div className='popup-container'>
        <div className='overlay'></div>
        <div className='popup' onClick =  {(event) => event.stopPropagation()}>
           {child}
        </div>
        </div>
    );
};

export default PopupForm;