import React, {useState} from 'react';

const InfoBlock = ({format, header, content}) => {
    
const [isHidden, setHidden] = useState('hidden')

format && console.log(format)

const isFormat = () => {return (<div className='info-block__description'>
  <div className='info-block__description_section'>{format.title}</div>
  <ul className='info-block__description_section'>{format.app.map(el => <li>{el}</li>)}</ul>
  <div className='info-block__description_section'>{format.description}</div>
</div>)
}

const checkObj = () => {
  if (typeof content == "object") {
    return (
      <ul className='info-block__description'>
        {Object.keys(content).map((key) => (
          <li className="info-block__description_section">{content[key].title}<ul>
            {content[key].description.map(el => <li>{el}</li>)}
            </ul></li>
        ))}
      </ul>
    );
  
  }
  else if (content.length === 0) {
    return (<div className='info-block__description'></div>)
  }
  

  else return (<div className='info-block__description'><div className='info-block__description_section'>{content}</div></div>)
}


const HandleClick = () => {
 isHidden === 'hidden' ? setHidden('displayed') : setHidden('hidden');
}  
    return (
        <div className='info-block__container'>
            <div onClick = {() => HandleClick()} className='info-block__header'>
                <div className='info-block__header_text'>{header}</div>
                <div className='info-block__header_image'></div>
            </div>
            <div className= {`info-block__text ${isHidden}`}>
            
            {format? isFormat() : checkObj()}
            </div>
        </div>
    );
};

export default InfoBlock;
