import React from "react";
import { Link } from "react-router-dom";

const Course = ({ courseObj }) => {
  const { id, type, name, description, length, language, program } = courseObj;

  return (
    <Link to={`/course/${id}`}>
      <div className="course ">
        <div className="course__type">{type}</div>
        <div className="course__info">
          <h4 className="course__info_header">{name}</h4>
          <div className="course__info_duration">{length}</div>
        </div>
        <div className="course__description">{description}</div>
        <ul className="course__description_sections">
          {Object.keys(program).map((key) => (
            <li className="course__description_section">
              {program[key].title}
            </li>
          ))}
        </ul>
        <div className="course__prof">
          {/* <div className="course__prof_image"></div> */}
          <div className="course__prof_name">Мова: {language}</div>
        </div>
      </div>
    </Link>
  );
};

export default Course;
