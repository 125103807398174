import React from "react";
import Footer from "../../../Main/Components/Footer/Footer";
import Header from "../../../Main/Components/Header/Header";

const Payment = () => {
  return (
    <div className="payment">
      <Header></Header>
      <div className="payment__container">
        <div className="payment__header">Реквізити для оплати</div>
        <div className="payment__text">
          <div className="payment__text_item">Код отримувача: 2759000035</div>
          <div className="payment__text_item">
            Рахунок отримувача: UA493052990000026002045013268
          </div>
          <div className="payment__text_item">
            Назва банку: АТ КБ "ПРИВАТБАНК"
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Payment;
