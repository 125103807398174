import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about">
      <div className="about__background left"></div>
      <div className="about__background right"></div>
      <div className="about__info">
        <div className="about__info_img"></div>
        <h1 className="about__info_header">Семінар-тренінг школа WTS</h1>

        <div>
          <div className="about__info_text">
            Усе про харчову безпеку і ресторанне господарство
          </div>
          <div className="about__info_text">
            Широкий спектр програм підвищення кваліфікації
          </div>
          <div className="about__info_text">
            Співпрацюємо з провідними навчальними закладами України
          </div>
          <div className="about__info_text">
            Допомагаємо впроваджувати HACCP легко і без зусиль
          </div>
        </div>
      </div>
      <div className="about__navigation">
        <div className="about__navigation_block"></div>
        <Link to="./courses" className="about__navigation_block">
          Онлайн-курси
        </Link>
        <Link to="#" className="about__navigation_block">
          Тестування
        </Link>
        <Link to="./articles" className="about__navigation_block">
          Статті і корисні матеріали
        </Link>
      </div>
    </div>
  );
};

export default About;
