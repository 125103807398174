import React from "react";

const ErrorPopup = ({ onClick }) => {
  return (
    <div className="error">
      <div className="error__overlay"></div>
      <div className="error__container">
        <div
          className="error__container_close-button"
          onClick={() => onClick()}
        >
          X
        </div>
        <div className="error__container_header">
          Нажаль, сертифікат не знайдено
        </div>
        <div className="error__container_text">
          Будь-ласка, перевірте правильність введених даних
        </div>
        <button
          className="error__container_ok-button"
          onClick={() => onClick()}
        >
          Зрозуміло
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;
