import React from "react";
import CertificatesTable from "./components/CertificatesTable";
import Header from "../Main/Components/Header/Header";
import Footer from "../Main/Components/Footer/Footer";

const Sertificates = () => {
  return (
    <div className="certificates__container">
      <Header></Header>
      <CertificatesTable />
      <Footer></Footer>
    </div>
  );
};

export default Sertificates;
