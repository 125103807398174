import {api} from '../http/index'

class AuthService {
    
    static async login(login, password) {
        return api.post('/login', {login, password})
    }

    static async registration(login, password, firstName, lastName, patronymic, email) {
        try {
        return api.post('/registration', {login, password, firstName, lastName, patronymic, email})}
        catch(e) {
            console.log('ошибка')
        }
    }

    static async logout() {
        return api.post('/logout')
    }

    static async checkUser() {
        return api.get('/refresh', {withCredentials: true})
    }
}

export {AuthService}