import axios from "axios";

export const API_URL = `https://w-ts.com.ua/server`;
// export const API_URL = `http://localhost:5000/server`;

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

// api.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
//     return config;
// })

// api.interceptors.response.use((config) => {
//     return config;
// },

export { api };
