import React, { useState, useContext, useEffect } from "react";
import { authContext } from "../../../../index";
import { observer } from "mobx-react-lite";

interface RegistrationFormData {
  login: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  email: string;
  password: string;
  checked: boolean;
}

const RegistrationForm: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [formData, setFormData] = useState<RegistrationFormData>({
    login: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    email: "",
    password: "",
    checked: false,
  });

  const [validationErrors, setValidationErrors] = useState<
    Partial<RegistrationFormData>
  >({});

  const { authStore } = useContext(authContext);

  useEffect(() => setError(authStore.error), [authStore.error]);

  console.log(error);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } 
    else if (name === 'login') {
      setFormData((prevData) => ({ ...prevData, [name]: value.toLowerCase()}));
    }
    else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newValidationErrors: Partial<RegistrationFormData> = {};

    if (formData.login.length <= 3) {
      newValidationErrors.login = "Логін має бути довше 3 символів";
    }

    if (formData.password.length < 8) {
      newValidationErrors.password = "Пароль має бути більше 8 символів";
    }

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }

    console.log(formData);
    authStore.setError('')
    authStore.registration(formData);

  };

  return (
    <div className="form__wrapper">
      <div className="form__header">Форма реєстрації нового користувача</div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__inputs">
          <div className="form__personal-info">
            <div className="form__personal-info_header">
              Обов'язково перевірте інформацію, бо вона буде відображатися на
              ваших сертифікатах
            </div>
            <label className="form__label user">Ім'я</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Ім'я"
              className="form__input name"
              required
            />

            <label className="form__label user">Прізвище</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Прізвище"
              className="form__input lastname"
              required
            />

            <label className="form__label user">По батькові</label>
            <input
              type="text"
              name="patronymic"
              value={formData.patronymic}
              onChange={handleChange}
              placeholder="По батькові"
              className="form__input surname"
              required
            />
          </div>
          <div className="form__auth-info">
            <div className="form__input-container">
              {validationErrors.login && (
                <div className="form__error-message">
                  {validationErrors.login}
                </div>
              )}

              <label className="form__label user">Логін</label>
              <input
                type="text"
                name="login"
                value={formData.login}
                onChange={handleChange}
                placeholder="Логін"
                className="form__input"
                required
              />
            </div>
            <label className="form__label ">Електронна пошта</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Електронна пошта"
              className="form__input"
              required
            />
            <div className="form__input-container">
              {validationErrors.password && formData.password?.length < 8 && (
                <div className="form__error-message">
                  {validationErrors.password}
                </div>
              )}
              <label className="form__label lock">Пароль</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Пароль"
                className="form__input"
                required
              />
            </div>
          </div>
        </div>
        {error.length > 0 && <div className="form__error">{error}</div>}
        <div className="form__checkbox">
          <label>
            <input
              type="checkbox"
              name="checked"
              required
              checked={formData.checked}
              onChange={handleChange}
            />
            Я погоджуюсь з умовами обробки персональних данних
          </label>
        </div>
        <button type="submit" className="form__submit">
          Зареєструватися
        </button>
      </form>
    </div>
  );
};

export default observer(RegistrationForm);
