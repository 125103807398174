import React from 'react';

const Levels = () => {
    return (
      <div className="levels">
        <div className="levels__header">
          Одним з наших основних направлень роботи є підготовка кваліфікованих спеціалістів в сфері харчової безпеки
        </div>

        <div className="levels__container">

        <div className="levels__level">
            <div className="levels__level_image first"></div>
            <div className="levels__level_container">
              <div className="levels__level_header">Базовий рівень</div>
              <div className="levels__level_text"> Для тих, хто бажає ознайомитись з поняттям харчової безпеки</div>
            </div>
          </div>
          <div className="levels__level">
            <div className="levels__level_image second"></div>
            <div className="levels__level_container">
              <div className="levels__level_header">Середній рівень</div>
              <div className="levels__level_text">Підійде для тих, хто вже знайомий з основами і хоче поглибити свої знання.</div>
            </div>
          </div>
          <div className="levels__level">
            <div className="levels__level_image third"></div>
            <div className="levels__level_container">
              <div className="levels__level_header">Продвинутий рівень</div>
              <div className="levels__level_text">Для тих, кто прагне самостійно впровадити HACCP на підприємстві з нуля</div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Levels;