import React from "react";
import Footer from "../Main/Components/Footer/Footer";
import Header from "../Main/Components/Header/Header";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="about-page">
      <Header></Header>

      <div className="about-page__header">
        {/* <div className="about-page__header_image"></div> */}
        <div className="about-page__header_text">СЕМІНАР-ТРЕНІНГ ШКОЛА WTS</div>
        <div className="about-page__header_buttons-container">
          <Link to="legal-info">
            <button className="about-page__header_button">
              Юридична інформація
            </button>
          </Link>
          <Link to="offer">
            <button className="about-page__header_button">
              Публічна оферта
            </button>
          </Link>
          <Link to="payment">
            <button className="about-page__header_button">
              Реквізити для оплати
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="about-page__main-text">
        Ми раді вітати вас у нашому центрі, що спеціалізується на навчанні
        за принципами та практикою HACCP – системою аналізу небезпек та контролю
        критичних точок. Ми пропонуємо унікальні освітні програми, розроблені
        для тих, хто прагне досконалості в галузі безпеки харчових продуктів.
      </div> */}
      <div className="about-page__sections">
        <div className="about-page__section">
          <div className="about-page__section_number">1</div>
          <div className="about-page__section_text">
            <div className="about-page__section_header">Наші програми:</div>
            <ul className="about-page__section_description">
              <li className="about-page__section_item">
                Основи HACCP: Наші експерти допоможуть вам розібратися в
                основних концепціях та принципах HACCP. Ви освоїте методику
                аналізу небезпек, визначення критичних контрольних точок та
                розробки ефективних планів моніторингу.
              </li>
              <li className="about-page__section_item">
                Рівень вдосконалення: Для тих, хто вже знайомий з основами, ми
                пропонуємо поглиблене вивчення HACCP. Ви навчитесь проводити
                більш складний аналіз, керувати ризиками та застосовувати
                систему на практиці.
              </li>
              <li className="about-page__section_item">
                Сертифікація та практика: Ми підготуємо вас до проходження
                сертифікації HACCP, надаючи не лише теоретичні знання, але й
                практичний досвід роботи з реальними кейсами з харчової
                промисловості.
              </li>
            </ul>
          </div>
        </div>
        <div className="about-page__section">
          <div className="about-page__section_number">2</div>
          <div className="about-page__section_text">
            <div className="about-page__section_header">
              Переваги онлайн навчання в нашому центрі
            </div>
            <ul className="about-page__section_description">
              <li className="about-page__section_item">
                Гнучкий розклад: Навчайтеся в зручний для вас час, забезпечуючи
                збалансований режим між навчанням і роботою.
              </li>
              <li className="about-page__section_item">
                Глобальний доступ: Наші програми доступні для студентів з усього
                світу. Ви можете отримати якісну освіту, не покидаючи дому.
              </li>
              <li className="about-page__section_item">
                Ефективність і економія часу: Вибирайте найефективніший темп
                навчання для себе і заощаджуйте час на дорогу до навчального
                закладу.
              </li>
              <li className="about-page__section_item">
                Зручність і комфорт: Ви можете вчитися в зручних умовах,
                обираючи своє робоче середовище і графік.
              </li>
              <li className="about-page__section_item">
                Безпека: В період пандемії або обмежень можливість онлайн
                навчання допомагає забезпечити вашу безпеку та здоров'я.
              </li>
            </ul>
          </div>
        </div>
        <div className="about-page__section">
          <div className="about-page__section_number">3</div>
          <div className="about-page__section_text">
            <div className="about-page__section_header">Чому обирають нас?</div>
            <ul className="about-page__section_description">
              <li className="about-page__section_item">
                Широкий спектр курсів: Ми пропонуємо різні рівні навчання - від
                основ до продвинутого, що дозволяє кожному студентові знайти
                оптимальний шлях розвитку.
              </li>
              <li className="about-page__section_item">
                Актуальність інформації: Наші програми постійно оновлюються з
                урахуванням сучасних трендів та вимог безпеки харчових
                продуктів.
              </li>
              <li className="about-page__section_item">
                Досвідчені інструктори: Наші викладачі - висококваліфіковані
                фахівці з багатим досвідом у галузі безпеки харчових продуктів
                та HACCP.
              </li>
              <li className="about-page__section_item">
                Індивідуальний підхід: Ми розуміємо потреби кожного студента і
                намагаємося надати найкращу підтримку та настанови для
                досягнення успіху.
              </li>
              <li className="about-page__section_item">
                Зв'язок з індустрією: Ми підтримуємо партнерство з
                підприємствами та організаціями галузі, що надає нашим студентам
                можливість отримати цінні інсайти та зв'язки.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="about-page__links">
        <div className="about-page__link">Публіча оферта</div>
        <div className="about-page__link">Умови доставки</div>
        <div className="about-page__link">Умови оплати</div>
      </div> */}
      <Footer></Footer>
    </div>
  );
};

export default AboutPage;
