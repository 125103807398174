import React from "react";
import schema from './schemas/schema.png';
import Header from "../../Main/Components/Header/Header";
import Footer from "../../Main/Components/Footer/Footer";

const ArticleOne = () => {

  console.log(schema)

  return (
    <div className="article">
      <Header></Header>
      <div className="article__container">
        <div className="article__header">
          Інформація для працівників, що задіяні у розробці та впроваджені
          системи харчової безпеки
        </div>
        <div className="article__description">
          Матеріал підготовлено відповідно до ст. 33 Закону України № 771 від
          22.07.2014 р. 'Про основні принципи та вимоги до безпечності та якості
          харчових продуктів' і Наказу Міністерства аграрної політики та
          продовольства України від 01.10.2012 р. № 590 'Про затвердження вимог
          щодо розробки, впровадження та застосування постійно діючих процедур,
          заснованих на принципах Системи управління безпечністю харчових
          продуктів (НАССР)
        </div>
        <div className="article__section-header">
          Визначення та посилання на законодавство
        </div>
        <div className="article__paragraph">
          <a href="https://zakon.rada.gov.ua/laws/show/771/97-%D0%B2%D1%80#Text">
            Стаття 20
          </a>{" "}
          Закону України «Про основні принципи та вимоги до безпечності та
          якості харчових продуктів» № 771/97-ВР встановлює, що всі оператори
          ринку харчових продуктів зобов'язані розробляти, вводити в дію та
          застосовувати постійно діючі процедури, засновані на принципах системи
          аналізу небезпечних факторів та контролю у критичних точках (HACCP).
        </div>
        <div className="article__paragraph">
          Оператор ринку харчових продуктів – суб’єкт господарювання, що
          провадить діяльність з метою отримання прибутку або без такої мети,
          при управлінні якого перебувають потужності, на яких здійснюється
          виробництво, реалізація та/або обіг харчових продуктів.
        </div>
        <div className="article__paragraph">
          Потужність – це місце або адреса, за якою відбувається виробництво,
          транспортування, зберігання та/або обіг харчових продуктів. Прикладом
          потужності є ресторан, кафе, піцерія, склад, магазин, лоток на базарі,
          транспортний засіб. Кожна харчова потужність оператора ринку повинна
          бути зареєстрована в державному реєстрі харчових потужностей згідно зі
          статтею 23, 25 ЗУ «Про основні принципи та вимоги до безпечності та
          якості харчових продуктів».
        </div>
        <div className="article__paragraph">
          Порядок розробки та впровадження HACCP регламентується Наказом № 590
          Мінагрополітики «Про затвердження Вимог щодо розробки, впровадження та
          застосування постійно діючих процедур, заснованих на принципах Системи
          управління безпечністю харчових продуктів (НАССР)».
        </div>
        <div className="article__paragraph">
          Перевірку впровадження та дієвості системи харчової безпеки HACCP
          здійснює Держпродспоживслужба. Існують як планові, так і позапланові
          перевірки харчових потужностей, які здійснюють державні інспектори.
          Перелік питань, що будуть перевірятися, можна подивитися в Наказі №
          446 та Наказі № 447 Мінагрополітики. Сертифікація розробленої системи
          не є обов’язковою і не вимагається при перевірці.
        </div>
        <div className="article__section-header">
          Розробка та впровадження програм-передумов HACCP
        </div>
        <div className="article__paragraph">
          Перед початком впровадження системи харчової безпеки на підприємстві
          необхідно створити групу HACCP згідно з Наказом (у довільній формі),
          яка буде відповідальна за розробку та впровадження системи. Група
          повинна включати керівника групи HACCP (1 особа) та членів групи HACCP
          (1-5 осіб). Бажано, щоб група складалася з різнопрофільних фахівців,
          наприклад, кухаря, відповідального за миття та дезінфекцію,
          відповідального за прийом харчових продуктів на потужність тощо.
          Зазвичай керівником групи HACCP призначають шеф-кухаря, директора або
          дієтичну медичну сестру (у навчальних закладах). Для дуже малих
          потужностей, де працює лише 1 особа, можна призначити її
          відповідальною без інших членів групи HACCP.
        </div>
        <div className="article__paragraph">
          Наказ про створення групи HACCP має бути затверджений підписом
          керівника підприємства. В наказі повинні бути зазначені ПІБ та посади
          керівника та членів групи, а також їх обов’язки у рамках впровадження
          та підтримки системи харчової безпеки.
        </div>
        <div className="article__paragraph">
          Після створення групи HACCP, рекомендується провести перше засідання з
          усіма її членами та затвердити план впровадження системи харчової
          безпеки у вигляді протоколу. Протокол має містити план впровадження,
          строки та відповідальних осіб.
        </div>
        <div className="article__paragraph">
          Будь-яка система харчової безпеки базується на 13
          програмах-передумовах HACCP. Розробкою цих програм повинна займатися
          раніше створена група HACCP. Можна використовувати шаблони документів,
          проте варто пам’ятати, що немає однакових підприємств з однаковими
          схемами приміщень, продуктами, постачальниками та небезпеками. Тому
          розробка документації повинна відбуватися індивідуально, а
          використання готових документів без їх адаптації до діяльності вашого
          підприємства не має сенсу."
        </div>
        <div className="article__section-header">Програми-передумови</div>

        <div className="article__programs">
          <ul className="article__programs_container">
            <li className="article__program">
              <div className="article__program_image">1</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо належного планування
                  виробничих, допоміжних і побутових приміщень.
                </div>
                <div className="article__program_text">
                  Під час розробки цієї програми рекомендовано побудувати схему
                  вашого приміщення. За основу можна взяти схеми БТІ або іншу
                  технічну документацію. Розділіть приміщення своєї потужності
                  на зони: чисту, брудну та зону середньої забрудненості,
                  виділіть їх різними кольорами. Наприклад, кухню слід віднести
                  до чистої зони та виділити зеленим кольором. Туалет та
                  приміщення для зберігання токсичних сполук слід віднести до
                  брудної зони та виділити червоним кольором. Інші приміщення,
                  такі як офісні та адміністративні, а також коридори, зазвичай
                  відносять до зони середньої забрудненості та виділяють жовтим
                  кольором. На схемі слід показати потоки рухів сировини,
                  готових продуктів, відходів, персоналу та відвідувачів.
                  Важливо розділити потоки рухів таким чином, щоб вони не
                  перетиналися. Якщо це неможливо зробити фізично, то треба їх
                  розділяти у часі. Наприклад, прийом товарів можна робити на
                  початку зміни до початку виробництва, а виносити сміття у
                  кінці зміни.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">2</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо території, стану
                  приміщень, обладнання, проведення ремонтних робіт, технічного
                  обслуговування обладнання, калібрування, а також заходів щодо
                  захисту харчових продуктів від забруднення та сторонніх
                  домішок.{" "}
                </div>
                <div className="article__program_text">
                  Детально проаналізуйте стан приміщень своєї потужності,
                  визначте, чи потребують вони ремонту, чи обладнання потребує
                  дотягувачів на дверях, а вікна - москітних сіток для
                  запобігання проникненню шкідників. Також перевірте, чи
                  матеріали підлоги, стелі та стін не мають тріщин та можуть
                  ефективно митися. Складіть перелік обладнання своєї
                  потужності, визначте, чи необхідна періодична повірка
                  обладнання, складіть графік обслуговування та повірок.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">3</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо планування та стану
                  комунікацій (вентиляції, водопроводів водопостачання і
                  водовідведення, електро- і газопостачання, освітлення).{" "}
                </div>
                <div className="article__program_text">
                  Створіть перелік вузлів комунікацій, які потребують
                  періодичного обслуговування, таких як фільтри систем
                  вентиляції, стояки каналізації, лічильники гарячої та холодної
                  води тощо. Рекомендовано побудувати схему освітлення. У
                  виробничих зонах слід використовувати безпечне освітлення,
                  лампи повинні бути захищені плафонами або арматурою, щоб
                  уникнути потрапляння розбитого скла у харчовий продукт.{" "}
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">4</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо безпеки води, льоду,
                  пара, допоміжних матеріалів для переробки (обробки) харчових
                  продуктів, предметів та матеріалів, що контактують з харчовими
                  продуктами.{" "}
                </div>
                <div className="article__program_text">
                  Якщо на потужності використовується вода як інгредієнт для
                  приготування страв, то така вода повинна мати сертифікати
                  відповідності. Рекомендується замовляти лабораторні
                  дослідження води, особливо якщо постачальник не викликає
                  довіри. Рекомендовано також побудувати схему водопостачання,
                  водовідведення та каналізації. Поруч з умивальниками доцільно
                  розмістити разові рушники, рідке мило та дезінфікуючий засіб з
                  інструкцією використання. Предмети, що контактують з харчовими
                  продуктами (посуд та пакувальні матеріали), повинні мати
                  висновки про безпечність. Багаторазовий посуд має бути
                  належним чином митим та дезінфікованим.{" "}
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">5</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР з питань чистоти поверхонь
                  та процедур прибирання виробничих, допоміжних, побутових
                  приміщень та інших поверхонь.{" "}
                </div>
                <div className="article__program_text">
                  Створіть графіки прибирання та дезінфекції приміщень,
                  обладнання та інших поверхонь, що контактують з харчовими
                  продуктами. Для дезінфекції використовуйте засоби,
                  зареєстровані МОЗ, які мають відповідні висновки.
                  Рекомендовано розраховувати використання засобів для миття та
                  дезінфекції на 14 днів та більше. Для прибирання різних по
                  ступеню забруднення зон рекомендовано використовувати окремий
                  інвентар. Наприклад, для туалетів використовуйте червоний
                  совок та відро, а для кухні - зелений.{" "}
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">6</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо здоров'я та гігієни
                  персоналу.
                </div>
                <div className="article__program_text">
                  Кожен працівник, який має контакт з харчовими продуктами,
                  повинен мати дійсну медичну книжку (згідно з Наказом МОЗ
                  України № 280) та пройти спеціалізоване навчання відповідно до
                  своєї діяльності. Під час державного аудиту слід мати
                  документальне підтвердження проходження навчання. Наприклад,
                  онлайн навчання на нашому сайті дозволить отримати сертифікат.
                  Недопустимо приймати на роботу осіб з відкритими ранами або
                  ознаками респіраторних захворювань. Під час карантину
                  обов'язковий контроль температури співробітників (до 37.2 °C)
                  з записом у журналі. Також слід мати достатній запас
                  індивідуальних засобів захисту (маски, респіратори, перчатки)
                  на 14 днів.{" "}
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">7</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо поводження з відходами
                  виробництва та сміттям, їх збір і вивезення з потужності.
                </div>
                <div className="article__program_text">
                  Якщо на потужності накопичуються токсичні відходи або відходи
                  тваринного походження, їх треба належним чином утилізувати.
                  Для підтвердження належного поводження з відходами слід мати
                  журнал обліку та договори з підрядними організаціями на
                  утилізацію. Рекомендовано побудувати схему з розташуванням
                  сміттєвих контейнерів та потоками сміття.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">8</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо контролю шкідників,
                  визначення видів, запобігання їх появі та засоби профілактики
                  та боротьби.
                </div>
                <div className="article__program_text">
                  Підприємство повинно або самостійно забезпечувати заходи по
                  боротьбі зі шкідниками (мухами, пацюками, тарганами і т.п.),
                  або укласти договір з підрядною організацією, яка займається
                  дератизацією та дезінсекцією. Рекомендовано побудувати схему
                  розміщення пасток та засобів для боротьби зі шкідниками.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">9</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо безпечного зберігання
                  та використання токсичних сполук і речовин.
                </div>
                <div className="article__program_text">
                  Якщо на потужності використовуються токсичні дезінфікуючі
                  засоби або прилади для боротьби зі шкідниками, які містять
                  токсичні сполуки, то слід забезпечити їх належне зберігання у
                  окремому приміщенні або у герметичних контейнерах у закритій
                  шафі. Місце зберігання таких засобів рекомендовано позначити
                  на графічній схемі приміщення. Персонал, який користується
                  засобами, що містять токсичні сполуки, повинен пройти
                  спеціалізоване навчання (на нашому сайті є відповідний
                  онлайн-курс з отриманням відповідного сертифіката).
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">10</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо специфікації та
                  контролю постачальників.
                </div>
                <div className="article__program_text">
                  Підприємство повинно визначити критерії для оцінки
                  постачальників. Необхідно відмовитися від співпраці з
                  постачальниками харчових продуктів, які не мають державної
                  реєстрації потужності або експлуатаційного дозволу, бажано
                  перевіряти наявність впровадження системи харчової безпеки
                  HACCP. Для сировини, що закуповується, необхідно забезпечити
                  контроль наявності документації, маркування, зовнішнього
                  вигляду та цілісності пакування. Іноді доцільно періодично
                  проводити вибіркові лабораторні перевірки партій харчових
                  продуктів для підтвердження їх безпечності.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">11</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР зі зберігання та
                  транспортування. Всі харчові продукти треба зберігати в
                  належних умовах з додатковим контролем температури та
                  вологості.{" "}
                </div>
                <div className="article__program_text">
                  Підприємство повинно забезпечити простежуваність продуктів за
                  принципом «крок вперед» та «крок назад», тобто необхідно
                  відслідковувати рух конкретного продукту (партії продуктів)
                  від постачальника до кінцевого споживача. Продукцію, яка
                  першою надійшла на потужність, а також продукцію, термін
                  придатності якої закінчується, слід реалізовувати у першу
                  чергу. Якщо на потужності використовуються транспортні засоби,
                  то вони також повинні забезпечити належні умови зберігання
                  харчових продуктів під час транспортування (температуру та
                  вологість), періодично мити та дезінфікувати.{" "}
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">12</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо контролю технологічних
                  процесів. Всі процеси, будь-яким чином пов'язані з харчовими
                  продуктами, необхідно контролювати за заданими критеріями.{" "}
                </div>
                <div className="article__program_text">
                  Для невеликих підприємств з незначним рівнем ризику ефективний
                  контроль технологічних процесів можливий завдяки
                  програмам-передумовам, для інших, додатковий контроль
                  здійснюється за допомогою планів HACCP з контрольними або
                  критичними контрольними точками (ККТ).
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">13</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Програма-передумова системи НАССР щодо маркування харчових
                  продуктів та інформування споживачів.{" "}
                </div>
                <div className="article__program_text">
                  На потужності маркування харчових продуктів та інформування
                  споживачів повинно забезпечуватись відповідно до вимог Закону
                  2639-VIII «Про інформацію для споживачів щодо харчових
                  продуктів». Виробники продуктів наносять маркування на
                  етикетку, заклади громадського харчування мають можливість
                  інформувати споживачів через меню та додаткові інформаційні
                  листи. Обов'язково треба зазначати термін придатності, склад
                  та алергени.{" "}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="article__section-header">Впровадження HACCP</div>
        <div className="article__paragraph">
          Після затвердження групи HACCP наказом керівника та впровадження 13
          програм-передумов, члени групи HACCP повинні приступити до аналізу
          існуючих технологічних процесів, у яких здійснюються операції з
          харчовими продуктами та присутні ризики. Наприклад, процес отримання
          сировини, процес зберігання продуктів, процес приготування страв.
          Рекомендовано побудувати ці схеми у графічному форматі.{" "}
        </div>
        <div className="article__paragraph">
          По кожному харчовому продукту (або групі продуктів з подібними
          характеристиками) група HACCP повинна створити опис: назву, склад,
          умови зберігання, терміни придатності, пакування, спосіб споживання.
          Якщо ж група HACCP самостійно планує виявляти небезпеки у харчовому
          продукті, то треба користуватися відповідними нормативними
          документами, такими як ДСТУ, ТУ, Наказ Мін АПК № 16, Наказ МОЗ № 548,
          Наказ МОЗ № 256, Наказ МОЗ № 368, Наказ МОЗ № 695.
        </div>
        <div className="article__paragraph">
          Всього існує 7 принципів, на яких заснована система HACCP.
        </div>
        <div className="article__section-header">7 принципів системи HACCP</div>
        <div className="article__programs">
          <ul className="article__programs_container" style={{display: 'flex', flexDirection: 'column'}}>
            <li className="article__program">
              <div className="article__program_image">1</div>
              <div className="article__program_container">
                <div className="article__program_header">
                  Проведення аналізу небезпечних факторів.
                </div>
                <div className="article__program_text">
                  Аналізуючи описи харчових продуктів та схеми технологічних
                  процесів, група HACCP повинна виявити наявні небезпеки на
                  кожному етапі процесу. Небезпечні фактори розділяються на
                  біологічні (гельмінти, сальмонела, лістерія), хімічні (цезій,
                  ртуть, стронцій) та фізичні (частки обладнання, пакування,
                  фізичні домішки). Частина біологічних небезпек може бути
                  виключена на етапі термообробки, хімічні небезпеки можна
                  контролювати завдяки перевіркам документації постачальників
                  (сертифікатів якості, висновків тощо) та лабораторних
                  аналізів, фізичні небезпеки можна контролювати візуально,
                  завдяки металошукачу та іншим пристроям. Кожен небезпечний
                  фактор необхідно оцінити за шкалою імовірності та потенційної
                  небезпеки для здоров’я людини.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">2</div>
              <div
                className="article__program_container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  
                }}
              >
                <div className="article__program_header">
                  Визначення критичних контрольних точок.
                </div>
                <div className="article__program_text">
                  Для значущих небезпечних факторів, які не можуть
                  контролюватися програмами-передумовами, необхідно обрати інші
                  засоби керування: контрольні або критичні контрольні точки
                  (ККТ).Для визначення ККТ рекомендовано використовувати наступне дерево рішень:
                </div>
              </div>
            </li>
            <img className = 'article__img'
                  src={schema}
                  
                 
                  alt="schema"
                
                ></img> 
            <li className="article__program">
              <div className="article__program_image">3</div>
              <div className="article__program_container">
                <div className="article__program_header">
                Встановлення граничних значень.
                </div>
                <div className="article__program_text">
                Кожна критична контрольна точка (ККТ) повинна бути вимірною та мати граничні значення, які відділяють безпечний продукт від небезпечного. Наприклад, межою критичної контрольної точки може бути діапазон часу приготування страви та температура термообробки. Граничні значення повинні бути обґрунтовані нормативними документами або власними дослідженнями. Зовнішній вигляд продукту або наявність документації не можуть бути ККТ, бо їх не можна виміряти та встановити допустимі межі. Такі заходи можна контролювати звичайними контрольними точками (КТ) або чек листами.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">4</div>
              <div className="article__program_container">
                <div className="article__program_header">
                Введення системи контролю за ККТ.
                </div>
                <div className="article__program_text">
                Після того, як критичні контрольні точки були ідентифіковані та для кожної з них визначені граничні межі, необхідно впровадити систему контролю (моніторингу) за значеннями показників у цих точках. Контроль може бути періодичним, наприклад, кожного часу, або після якоїсь дії: після випікання страви, наприкінці зміни тощо. Значення показників треба фіксувати та зберігати. Фіксувати ці значення показників у протоколах, журналах чи чек-листах.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">5</div>
              <div className="article__program_container">
                <div className="article__program_header">
                Встановлення коригувальних дій.
                </div>
                <div className="article__program_text">
                Для кожної критичної контрольної точки (ККТ) треба задати дії, які необхідно виконати, якщо значення вимірюваного показника вийде за задані граничні межі, та відповідальних за ці дії осіб. Необхідно визначити та задокументувати причину невідповідності, відновити контроль над процесом та утилізувати небезпечний продукт.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">6</div>
              <div className="article__program_container">
                <div className="article__program_header">
                Перевірка (валідація та верифікація) для підтвердження того, що система HACCP працює ефективно.
                </div>
                <div className="article__program_text">
                Періодично (не рідше одного разу на рік) необхідно проводити перевірки, щоб переконатися, що система HACCP працює ефективно. Також обов'язково це робити при оновленні обладнання, зміні технологічних процесів та асортименту продукції.

Якщо на потужності ідентифіковано хоча б одну ККТ, то необхідно сформувати спеціальний документ: план HACCP. У цей документ необхідно включити перелік всіх етапів технологічних процесів, критичні контрольні точки (ККТ), їх граничні межі.

Рекомендовано перевіряти розроблені документи, особливо план HACCP, на актуальність посилань на нормативні документи. Під час перевірки слід оцінювати ефективність розробленої системи шляхом аналізу скарг від споживачів, перегляду коригуючих дій по виявленим невідповідностям.
                </div>
              </div>
            </li>
            <li className="article__program">
              <div className="article__program_image">7</div>
              <div className="article__program_container">
                <div className="article__program_header">
               Процедури ведення записів та документації.
                </div>
                <div className="article__program_text">
                Оператор ринку повинен мати докази впровадження та ефективності системи HACCP. Для цього треба розробити наступну документацію: наказ про створення групи HACCP, інструкції (по прибиранню, миттю рук тощо), протоколи (про засідання групи HACCP, про коригувальні дії тощо), журнали або чек-листи, плани HACCP, описи продуктів, схеми процесів тощо. Ці документи повинні періодично оновлюватись та заповнюватись.
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ArticleOne;
