import {api} from '../http/index';



class MailService {
    
    static async sendMail(formData) {
        try {
        return api.post('/send-email', {formData})}
        catch(e) {
            console.log('ошибка')
        }
    }

}

export {MailService}