import React, {useContext} from 'react';
import sertificate from './sertificate.jpg';
import stamp from './stamp.png';
import { authContext } from '../..';
import { Page, Text, View, Document, Image, StyleSheet, usePDF } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';





Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', // Replace with the actual font URL
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    transform: 'rotate(90deg)',
    backgroundColor: 'transparent' // Set the page background to transparent
  },


  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  background: {
    position: 'relative', 
    transform: 'rotate(90deg)',
    width: '100%',
    height: '100%'

  },
  section1: {
    margin: '180px auto 10px',
    color: 'gray',
    fontWeight: '700',
    fontSize: '50px'
    
  },
  section2: {
    color: 'gray',
    margin: '10 auto',
    fontSize: '20px',
    fontWeight: '00',
    
  },
  section3: {
    margin: ' 5 auto',
    color: 'gray',
    fontWeight: '700',
    fontSize: '40px'
    
  },
  section4: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'end',
    fontFamily: 'Roboto',
    height: '150px',

    
  },
  signature: {
    width: '300px',
    position: 'absolute',
    fontSize: '16px',
    color: 'gray',
    zIndex: 1,
    left: 0,
    top: '80px',
  },

  image: {
    zIndex: 2,
    position: 'relative',
    width: '170px',
    height: '150px',
    opasity: '50%',
  }
});


const CreatePDF = ({bearer, testName}) => {


 const MyDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Background Image */}
        <View style={styles.backgroundContainer}>
          <Image
            src = {sertificate}
            style={styles.background}
          />
        </View>
        
        {/* Content */}
  
        <View style={styles.section1}>
          <Text style={{ fontFamily: 'Roboto' }}>Сертифікат</Text>
        </View> 
        <View style={styles.section2}>
          <Text style={{ fontFamily: 'Roboto' }}>Засвідчує, що</Text>
        </View> 
        <View style={styles.section3}>
          <Text style={{ fontFamily: 'Roboto' }}>{bearer}</Text>
        </View> 
        <View style={styles.section2}>
          <Text style={{ fontFamily: 'Roboto' }}>успішно пройшов(ла) тестування по курсу</Text>
        </View> 
        <View style={styles.section2}>
          <Text style={{ fontFamily: 'Roboto' }}>{testName}</Text>
        </View>
        <View style={styles.section4}>
        {/* <Image
         src = {stamp}
         style={styles.image}
        /> */}
        <Text style={styles.signature}>Хорошилов А.А.______________</Text>
  
        <Image
            src = {stamp}
            style={styles.image}
          />
        </View>
      </Page>
    </Document>
  );

  const [instance, updateInstance] = usePDF({ document: MyDoc });




  return (
    <a className='pdf__button' href={instance.url} download="certificate.pdf"> Завантажити сертифікат</a>

  );
  }
export default CreatePDF;

