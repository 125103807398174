import React from 'react';
import './certificates.scss'

interface CertificateItemProps {
        id: number;
        name: string;
        number: string;
        date: string;
        bearer?: string;
        description: string;
      }




const CertificateItem: React.FC<CertificateItemProps> = ({id, name,number, date, bearer, description, }) => {
    return (
        <div className='certificate-item__container' key = {id}>
            <div className='certificate-item__text'>{name}</div>
            <div className='certificate-item__text'>Серія: {number}</div>
            <div className='certificate-item__text'>{bearer}</div>
            <div className='certificate-item__text'>Дата видачі: {date}</div>
            <div className='certificate-item__text'>Період навчання: {description}</div>
        </div>
    );
};

export default CertificateItem;