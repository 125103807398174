import {makeAutoObservable} from "mobx";   
import { MailService } from "../services/MailService";




export default class MailStore {

    constructor() {
        makeAutoObservable(this);
    }
    


    async sendMail(formData) {
        try {
        console.log(formData)
            const response = await MailService.sendMail(formData)
         console.log(response)
         
        }

        catch(e) {
            console.log(e)
        }
    }

}

