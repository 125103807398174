import React, {createContext} from 'react';
import {useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { FC, ReactNode } from 'react';
import { Provider } from 'mobx-react';
import AuthStore from './store/authStorage';
import BalanceStore from './store/balanceStorage';
import MailStore from './store/mailStorage';
import TestsStore from './store/testsStorage';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';




const Wrapper = ({ children }) => {
  const location = useLocation();
  
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  
  return children;
};


const root = ReactDOM.createRoot(
  document.getElementById('root')
)
 

export const authStore = new AuthStore()
export const authContext =  createContext({authStore})
export const balanceStore = new BalanceStore()
export const balanceContext =  createContext({balanceStore})
export const mailStore = new MailStore()
export const mailContext =  createContext({mailStore})
export const testsStore = new TestsStore()
export const testsContext =  createContext({testsStore})



root.render(
    <Provider value = {{authStore, mailStore, testsStore, balanceStore}}>
    <BrowserRouter>
    <Wrapper>
    <App />
    </Wrapper> 
    </BrowserRouter>
    </Provider>
  
);

