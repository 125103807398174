import React from 'react';
import { Link } from 'react-router-dom';
const PopupMenu = () => {
    return (
        <div className='popup-menu'>
            <div className='popup-menu__container'>
                {/* <Link className = 'popup-menu__item' to = '/tests'>Тестування</Link> */}
                <Link className = 'popup-menu__item' to = '/courses'>Актуальні курси</Link>
                <Link className = 'popup-menu__item' to = '/about'>Про нас</Link>
                <Link className = 'popup-menu__item' to = '/sertificates'>Видані сертифікати</Link>
                <Link className = 'popup-menu__item' to = '/articles'>Корисні посилання</Link>
                <Link className = 'popup-menu__item' to = '/programs'>Програми курсів</Link>
            </div>            
        </div>
    );
};

export default PopupMenu;