import React, { useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { authContext, balanceContext } from '../../index';
import { observer } from 'mobx-react';



interface LoginFormData {
  login: string;
  password: string;
  checked: boolean;
}

const LoginForm: React.FC = () => {


  const [error, setError] = useState<string>('')

  const [formData, setFormData] = useState<LoginFormData>({
    login: '',
    password: '',
    checked: false
  });

const {authStore} = useContext(authContext)

  useEffect(() => setError(authStore.error), [authStore.error])
console.log(error)



  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.login(formData.login, formData.password);


  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } 
    else if (name === 'login') {
      
      setFormData((prevData) => ({ ...prevData, [name]: value.toLowerCase()}));

    }
    
    else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

    return (
        <div className = 'login-form'>
          <div className="login-form__container">
          <div className="login-form__header">
            <h3 className="login-form__header_sign-in">Логін</h3>
            <Link to = '/auth/registration'> <div className="login-form__header_button">Реєстрація</div></Link>
          </div>
          {error?.length > 0 && <div className='login-form_message'>{error}</div>}
          <form onSubmit={handleLogin}>

          <div className="login-form__inputs">
          <label className="login-form__label"></label>
          <input
            type="text"
            name="login"
            value={formData.login}
            onChange={handleChange}
            placeholder="Логін"
            className="login-form__input"
            required
          />
              <label className="login-form__label"></label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Пароль"
            className="login-form__input"
            required
          /> 
          <div className="login-form__checkbox">
              <label className ='login-form__checkbox_container'>
                <input
                  className='login-form__checkbox_label'
                  type="checkbox"
                  name="checked"
                  checked={formData.checked}
                  onChange={handleChange}
                />
                запам'ятати мене
              </label>
            </div>
          </div>
             <div>
              <input className="login-form__submit" 
              type="submit" value="Увійти"/>
            </div>
          </form>  
        </div>
        </div>
      );
};

export default observer(LoginForm)